import Phaser from 'phaser';

import Button from '../button/Button';
import ClaimButton from '../button/ClaimButton';
import configs from '../../configs/configs';

const { width } = configs;

const px = 40;
const buttonWidth = 288;

class Footer extends Phaser.GameObjects.Container {
  interval;
  scene;
  isSimulator;

  constructor(scene, y) {
    super(scene, 0, 0);

    this.scene = scene;
    this.isSimulator = scene.name === 'TutorialScene';

    this.buttonWar = new Button(
      scene,
      buttonWidth / 2 + px,
      y,
      'button-war',
      'button-war-pressed',
      () => {
        scene.popupBuy?.setVisible(false);

        const current = scene.popupWar?.visible;
        this.tooltipNextWar.setVisible(current);
        this.timeText.setVisible(current);

        if (current) {
          scene.popupWar?.close();
        } else {
          scene.popupWar?.open();
        }
      },
      { sound: 'button-1' }
    );

    this.deployNowBadge = scene.add
      .image(buttonWidth / 2 + px - 5, y + this.buttonWar.height / 2, 'deploy-now')
      .setOrigin(0.5, 1);

    this.tooltipNextWar = scene.add
      .image(buttonWidth / 2 + px + 40, y - this.buttonWar.height / 2 + 30, 'tooltip-next-war')
      .setOrigin(0.5, 1);

    this.timeText = scene.add
      .text(buttonWidth / 2 + px - 20, y - this.tooltipNextWar.height + 75, `00h00m`, {
        // font: 'bold 60px Arial',
        fontSize: '50px',
        fontFamily: 'WixMadeforDisplayExtraBold',
        color: '#29000B',
      })
      .setOrigin(0, 0.5);

    this.buttonBuy = new Button(
      scene,
      width - px - buttonWidth / 2,
      y,
      'button-buy',
      'button-buy-pressed',
      () => {
        scene.popupWar?.setVisible(false);
        this.tooltipNextWar.setVisible(true);
        this.timeText.setVisible(true);
        scene.popupBuy?.setVisible(!scene.popupBuy?.visible);
      },
      { sound: 'button-1' }
    );

    this.buttonClaim = this.isSimulator
      ? new Button(scene, width / 2, y, 'tutorial-claim-inactive-btn', 'tutorial-claim-inactive-btn', () => {})
      : new ClaimButton(scene, width / 2, y);

    this.add(this.buttonWar);
    this.add(this.deployNowBadge);
    this.add(this.tooltipNextWar);
    this.add(this.timeText);
    this.add(this.buttonBuy);
    this.add(this.buttonClaim);

    scene.events.on('s-set-next-war-time', ({ time }) => {
      const now = Date.now();
      const diffInMins = (time - now) / (60 * 1000);
      const hours = Math.floor(diffInMins / 60);
      const mins = Math.floor(diffInMins % 60);

      const timeText = `${hours}h ${mins.toString().padStart(2, '0')}m`;
      this.timeText.text = timeText;

      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => this.calculateNextWarTime({ time }), 60 * 1000);
    });

    scene.events.on('s-set-season-status', ({ status }) => {
      if (status !== 'open') {
        this.deployNowBadge?.setVisible(false);
        this.tooltipNextWar?.setVisible(false);
        this.timeText?.setVisible(false);
      }
    });
  }

  calculateNextWarTime({ time }) {
    const now = Date.now();
    const diffInMins = (time - now) / (60 * 1000);

    if (diffInMins < 560) {
      this.scene.events.emit('s-get-next-war-time');
      return;
    }

    const hours = Math.floor(diffInMins / 60);
    const mins = Math.floor(diffInMins % 60);

    const timeText = `${hours}h ${mins.toString().padStart(2, '0')}m`;
    this.timeText.text = timeText;
  }
}

export default Footer;
