import Phaser from 'phaser';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import { formatter } from '../../../../utils/numbers';
import { colors, fontFamilies } from '../../../../utils/styles';

const buttonWidth = 506;
const btnGap = 50;

const normalTextStyle = {
  fontSize: '60px',
  color: '#29000B',
  fontFamily: fontFamilies.semiBold,
};

const boldTextStyle = {
  ...normalTextStyle,
  fontFamily: fontFamilies.extraBold,
};

class PopupDepositConfirm extends Popup {
  constructor(scene) {
    super(scene, 'popup-tiny', { title: 'Warning' });
    this.scene = scene;

    this.closeBtn = new TextButton(
      scene,
      this.popup.x,
      this.popup.y + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => this.close(),
      'OK',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.closeBtn);

    const textX = this.popup.x - this.popup.width / 2 + 100;
    const text1Y = this.popup.y - this.popup.height / 2 + 220;
    const text2Y = text1Y + 75;
    const text3Y = text2Y + 100;

    this.text1 = scene.add.text(textX, text1Y, 'Only send ETH to this address on', normalTextStyle).setOrigin(0, 0.5);
    this.text2 = scene.add
      .text(textX, text2Y, 'Ethereum network.', { ...boldTextStyle, color: colors.brown })
      .setOrigin(0, 0.5);
    this.text3 = scene.add.text(textX, text3Y, 'Otherwise you may lose your fund.', normalTextStyle).setOrigin(0, 0.5);

    this.add([this.text1, this.text2, this.text3]);
  }
}

export default PopupDepositConfirm;
