import Popup from './Popup';
import PopupProcessing from './PopupProcessing';
import PopupConfirm, { icon1Gap } from './PopupConfirm';
import TextButton from '../button/TextButton';
import Button from '../button/Button';
import configs from '../../configs/configs';
import { customFormat, formatter } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { fibonacis, maxStepCount, maxStepSizeIndex } from '../../../../utils/constants';
import { estimateNumberOfMachineCanBuy, calculateNextMachineBuyPriceBatch } from '../../../../utils/formulas';

const { width, height } = configs;
const DEFAULT_QUANTITY = 1;
const INTERVAL = 100;
const largeBlackExtraBold = {
  fontSize: fontSizes.large,
  color: colors.black,
  fontFamily: fontFamilies.extraBold,
};
const smallGreenBold = { fontSize: fontSizes.small, color: colors.green, fontFamily: fontFamilies.bold };

class PopupBuyGangster extends Popup {
  isSimulator = false;
  status = null;
  gas = 0;
  numberOfMachines = 0;
  networth = 0;
  networthBase = 0;
  networthPerDay = 0;
  rateIncrease = 0;
  balance = 0;
  basePrice = 0;
  unitPrice = 0;
  whitelistPrice = 0;
  quantity = DEFAULT_QUANTITY;
  maxQuantity = 10;
  isWhitelisted = false;
  whitelistAmountLeft = 0;
  mintFunction = 'mint';
  targetDailyPurchase = 1;
  pricePower = 0;
  targetPrice = 0;
  totalSold = 0;
  days = 1;
  onCompleted;
  isTrackingSales = false;
  started = false;
  startingPrice = 0;
  stepSizeIndex = 0;
  stepCount = 0;
  referralDiscount = 0;
  interval = null;

  constructor(scene, { onCompleted, ...configs } = {}) {
    super(scene, 'popup-buy-gangster', { title: 'Buy Gangsters', ...configs });
    this.scene = scene;
    this.onCompleted = onCompleted;
    const isSimulator = scene.name === 'TutorialScene';
    this.isSimulator = isSimulator;

    this.popupBuyProcessing = new PopupProcessing(scene, {
      sound: 'gangster',
      completedEvent: 's-buy-gangster-completed',
      completedIcon: 'icon-gangster-buy-done',
      failedIcon: 'icon-gangster-buy-fail',
      description: '',
      onCompleted,
    });
    scene.add.existing(this.popupBuyProcessing);

    this.popupConfirm = new PopupConfirm(scene, this, {
      title: 'Buy Gangsters',
      action: 'buy',
      icon1: 'icon-gangster-medium',
      icon2: 'icon-coin-small',
      onConfirm: () => {
        if (!this.quantity) return;
        this.popupBuyProcessing.initLoading(
          `Hiring ${this.quantity} Gangster${this.quantity > 1 ? 's' : ''}.\nPlease, wait`
        );

        scene.events.emit('s-buy-gangster', { quantity: this.quantity, mintFunction: this.mintFunction });
      },
      onOpen: () => {
        scene.events.emit('s-enable-machine-sales-tracking');
      },
      onClose: () => scene.events.emit('s-disable-machine-sales-tracking'),
    });
    scene.add.existing(this.popupConfirm);
    this.popupConfirm.updateTextLeft(`1${icon1Gap}unit`);

    this.upgradeBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        if (isSimulator) {
          this.quantity = 1;
          this.popupBuyProcessing.initLoading(
            `Hiring ${this.quantity} Gangster${this.quantity > 1 ? 's' : ''}.\nPlease, wait`
          );
          this.onCompleted = null;
          this.close();

          scene.events.emit('s-buy-gangster', {
            quantity: this.quantity,
            mintFunction: this.mintFunction,
            isSimulator: true,
          });
        } else {
          this.close();
          this.popupConfirm.open();
        }
      },
      'Buy',
      { fontSize: '82px', sound: 'buy' }
    );
    this.add(this.upgradeBtn);
    this.upgradeBtn.setDisabledState(!isSimulator);

    this.numberOfMachinesTitle = scene.add
      .text(this.popup.x + 80, this.popup.y - this.popup.height / 2 + 215, 'Gangsters: ', {
        fontSize: fontSizes.large,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfMachinesTitle);

    this.numberOfMachinesText = scene.add
      .text(this.popup.x + 150, this.numberOfMachinesTitle.y, '', {
        fontSize: fontSizes.extraLarge,
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfMachinesText);

    const rateY = this.popup.y - 120;
    this.rateText = scene.add.text(this.popup.x + 320, rateY, '0', largeBlackExtraBold).setOrigin(1, 0);
    this.rateIncreaseText = scene.add
      .text(this.popup.x + this.popup.width * 0.4, rateY + 80, '+0 /d', smallGreenBold)
      .setOrigin(1, 0);
    this.add(this.rateText);
    this.add(this.rateIncreaseText);

    this.networthText = scene.add.text(this.popup.x + 380, rateY + 180, '0', largeBlackExtraBold).setOrigin(1, 0);
    this.networthIncreaseText = scene.add
      .text(this.popup.x + this.popup.width * 0.4, this.networthText.y + 80, '+0', smallGreenBold)
      .setOrigin(1, 0);
    this.add(this.networthText);
    this.add(this.networthIncreaseText);

    const counterY = this.popup.y + this.popup.height / 2 - 260;
    const minusBtnX = this.popup.x - this.popup.width / 2 + 310;

    this.priceTextX = this.popup.x + (isSimulator ? 200 : 160);
    this.priceText = scene.add.text(this.priceTextX, counterY, '0', largeBlackExtraBold).setOrigin(0, 0.5);
    this.add(this.priceText);

    // WL mint
    this.alternativePrice = scene.add.text(this.priceTextX, counterY - 110, '0', largeBlackExtraBold).setVisible(false);
    this.priceStrikethrough = scene.add
      .rectangle(this.priceTextX + 20, counterY, this.priceText.width, 5, 0x29000b)
      .setVisible(false);
    this.add(this.alternativePrice);
    this.add(this.priceStrikethrough);

    this.gasPrice = scene.add
      .text(this.priceTextX, counterY, '+0 ETH (gas)', {
        fontSize: fontSizes.small,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, -1);
    this.add(this.gasPrice);
    this.insufficientBalance = scene.add
      .text(this.priceTextX, counterY + 48, 'Insufficient $GOLD', {
        fontSize: fontSizes.small,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, -1)
      .setVisible(false);
    this.add(this.insufficientBalance);

    if (!isSimulator) {
      this.coin = scene.add
        .image(this.priceText.x + this.priceText.width + 40, counterY, 'icon-coin-small')
        .setOrigin(0, 0.5);
      this.add(this.coin);

      this.infoButton = new Button(
        scene,
        this.coin.x + this.coin.width + 40,
        counterY - 15,
        'button-info',
        'button-info-pressed',
        () => {
          this.close();
          scene.popupGangsterPrice?.open();
        },
        { sound: 'open' }
      );
      this.add(this.infoButton);
    }

    this.background = scene.add.rectangle(0, 0, width, height, 0x260343, 0.8).setOrigin(0, 0).setVisible(false);
    this.add(this.background);

    this.quantityPlane = scene.add.image(minusBtnX + 170, counterY, 'quantity-plane').setOrigin(0.5, 0.5);
    this.add(this.quantityPlane);

    this.minusBtn = new TextButton(
      scene,
      minusBtnX,
      counterY,
      'button-square',
      'button-square-pressed',
      () => {
        if (this.quantity > DEFAULT_QUANTITY) {
          this.quantity--;
          this.updateValues();
        }
      },
      '-',
      {
        disabledImage: 'button-square-disabled',
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.quantity > DEFAULT_QUANTITY) {
              this.quantity = Math.max(0, this.quantity - stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.minusBtn.setDisabledState(isSimulator);
    this.add(this.minusBtn);

    this.plusBtn = new TextButton(
      scene,
      minusBtnX + 350,
      counterY,
      'button-square',
      'button-square-pressed',
      () => {
        if (this.quantity < this.maxQuantity) {
          this.quantity++;
          this.updateValues();
        }
      },
      '+',
      {
        disabledImage: 'button-square-disabled',
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.quantity < this.maxQuantity) {
              this.quantity = Math.min(this.maxQuantity, this.quantity + stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.plusBtn.setDisabledState(isSimulator);
    this.add(this.plusBtn);

    this.quantityText = scene.add.text(minusBtnX + 170, counterY, this.quantity, {
      fontSize: '60px',
      fontFamily: fontFamilies.extraBold,
      color: '#7C2828',
    });
    this.quantityText.setOrigin(0.5, 0.5);
    this.add(this.quantityText);

    scene.events.on('s-buy-gangster-completed', () => {
      this.quantity = DEFAULT_QUANTITY;
      this.updateValues();
    });

    scene.events.on('s-set-gas-mint', ({ gas }) => {
      if (isNaN(gas)) return;

      this.gas = gas;
      this.updateValues();
    });

    scene.events.on('s-set-season-status', ({ status }) => {
      this.status = status;
      if (status !== 'open') {
        this.upgradeBtn.setDisabledState(true);
      }
    });

    scene.events.on(
      's-set-machines',
      ({
        numberOfMachines,
        balance,
        maxPerBatch,
        dailyReward,
        networthBase,
        networthPerDay,
        isWhitelisted,
        whitelistAmountLeft,
        basePrice,
        basePriceWhitelist,
        targetDailyPurchase,
        pricePower,
        targetPrice,
        totalSold,
        days,
        isTrackingSales,
        started,
        startingPrice,
      }) => {
        this.balance = balance;
        this.basePrice = basePrice;
        this.whitelistPrice = basePriceWhitelist;
        this.numberOfMachines = numberOfMachines;
        this.targetDailyPurchase = targetDailyPurchase;
        this.pricePower = pricePower;
        this.targetPrice = targetPrice;
        this.totalSold = totalSold;
        this.days = days;
        this.networthBase = networthBase;
        this.networthPerDay = networthPerDay;
        this.rateIncrease = dailyReward;
        this.isWhitelisted = isWhitelisted;
        this.whitelistAmountLeft = whitelistAmountLeft;
        this.mintFunction = isWhitelisted && whitelistAmountLeft ? 'mintWL' : 'mint';
        this.isTrackingSales = isTrackingSales;
        this.started = !!started;
        this.startingPrice = startingPrice;

        this.numberOfMachinesText.text = `${numberOfMachines.toLocaleString()}`;
        this.numberOfMachinesTitle.x = this.popup.x + 80 - this.numberOfMachinesText.width / 2 + 5;
        this.numberOfMachinesText.x =
          this.numberOfMachinesTitle.x +
          this.numberOfMachinesTitle.width / 2 +
          this.numberOfMachinesText.width / 2 +
          10;
        this.rateText.text = `${formatter.format(numberOfMachines * dailyReward)}`;
        this.maxQuantity =
          isWhitelisted && whitelistAmountLeft ? Math.min(whitelistAmountLeft, maxPerBatch) : maxPerBatch;
        this.updateValues();
      }
    );
    scene.events.on('s-set-referral-discount', ({ discount }) => {
      this.referralDiscount = discount;
      this.updateValues();
    });
    scene.events.on('s-set-networth', ({ networth }) => {
      this.networth = networth;
      this.networthText.text = `${networth.toLocaleString()}`;
      this.updateValues();
    });
  }

  offInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  onInterval() {
    this.offInterval();
    this.interval = setInterval(() => {
      this.scene.events.emit('s-get-machines');
    }, 60 * 1000);
  }

  onOpen() {
    this.scene.events.emit('s-get-networth');
    this.scene.events.emit('s-enable-machine-sales-tracking');
    this.scene.events.emit('s-get-referral-discount');
    this.scene.events.emit('s-get-gas-mint');
    this.scene.events.emit('s-get-gangster-price', { timeMode: this.scene.popupGangsterPrice.timeMode });
    this.onInterval();
  }

  cleanup() {
    this.onCompleted?.();
    this.scene.events.emit('s-disable-machine-sales-tracking');
    this.offInterval();
  }

  updateValues() {
    this.networthIncreaseText.text = `+${(this.networthBase * this.quantity).toLocaleString()} instant & +${(
      this.networthPerDay * this.quantity
    ).toLocaleString()} per day`;
    this.rateIncreaseText.text = `+${(this.rateIncrease * this.quantity).toLocaleString()} /d`;

    if (this.isSimulator) {
      this.priceText.text = 'FREE';
      this.gasPrice.text = '';
      return;
    }

    this.unitPrice = this.mintFunction === 'mintWL' ? this.whitelistPrice : this.basePrice;
    this.estimatedMaxPurchase = estimateNumberOfMachineCanBuy(
      this.balance,
      this.totalSold,
      this.days,
      this.targetDailyPurchase,
      this.pricePower,
      this.targetPrice,
      this.basePrice,
      this.maxQuantity
    );

    const estimatedPrice = this.started
      ? calculateNextMachineBuyPriceBatch(
          this.totalSold,
          this.days,
          this.targetDailyPurchase,
          this.pricePower,
          this.targetPrice,
          this.basePrice,
          this.quantity
        ).total
      : this.startingPrice;

    this.quantityText.text = `${this.quantity}`;
    this.priceText.text = `${customFormat(estimatedPrice, 1)}`;
    const discountNote = ` (-${this.referralDiscount * 100}%)`;
    const alternativePrice = customFormat(estimatedPrice * (1 - this.referralDiscount), 1);
    this.alternativePrice.text = `${alternativePrice}${discountNote}`;
    this.priceStrikethrough.width = this.priceText.width;
    const formattedGas = customFormat(this.gas, 4) === '0' ? '<0.0001' : customFormat(this.gas, 4);
    this.gasPrice.text = `+${formattedGas} ETH (gas)`;
    if (this.coin && this.infoButton) {
      this.coin.x = this.priceText.x + this.priceText.width + 20;
      this.infoButton.x = this.coin.x + this.coin.width + 40;
    }

    const hasDifferentPrice = this.referralDiscount > 0;
    this.popupConfirm.updateTextLeft(`${this.quantity}${icon1Gap}unit${this.quantity > 1 ? 's' : ''}`);
    this.popupConfirm.updateTextRight(
      hasDifferentPrice ? alternativePrice : formatter.format(estimatedPrice.toPrecision(3))
    );
    this.alternativePrice.setVisible(hasDifferentPrice);
    this.priceStrikethrough.setVisible(hasDifferentPrice);
    const insufficientBalance = this.quantity > this.estimatedMaxPurchase;
    this.insufficientBalance.setVisible(insufficientBalance);
    this.upgradeBtn.setDisabledState(this.status !== 'open' || insufficientBalance || !this.isTrackingSales);
  }
}

export default PopupBuyGangster;
