import Phaser from 'phaser';

import Balance from './Balance';
import configs from '../../configs/configs';
import { formatter, customFormat } from '../../../../utils/numbers';
import { fontFamilies, fontSizes } from '../../../../utils/styles';

const { width } = configs;

const buttonWidth = 291;
const gap = buttonWidth + 20;

class Header extends Phaser.GameObjects.Container {
  timeout;
  headerY = 0;
  xGangBalance = 0;
  poorTokenBalance = 0;

  constructor(scene, y, { tokenClick, ethClick } = {}) {
    super(scene, 0, 0);

    this.headerY = y;

    this.addedAmountXGang = scene.add
      .text(width / 2 - 10 - gap - buttonWidth / 2, y, '', {
        fontSize: fontSizes.medium,
        color: '#389d2a',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0);
    this.addedAmountXGang.setStroke('#fff', 10);

    this.addedAmountPoorToken = scene.add
      .text(width / 2 + 10 + buttonWidth / 2 + gap, y, '', {
        fontSize: fontSizes.medium,
        color: '#d04afc',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0);
    this.addedAmountPoorToken.setStroke('#fff', 10);

    this.xTokenBalance = new Balance(scene, width / 2 - 10 - gap - buttonWidth / 2, y, null, 'xtoken-balance', 0);
    this.tokenBalance = new Balance(
      scene,
      width / 2 - 10 - buttonWidth / 2,
      y,
      () => {
        if (tokenClick) {
          tokenClick();
        } else {
          scene.popupSwap?.open();
        }
      },
      'token-balance',
      0
    );
    this.ethBalance = new Balance(
      scene,
      width / 2 + 10 + buttonWidth / 2,
      y,
      () => {
        if (ethClick) {
          ethClick();
        } else {
          scene.popupDeposit?.open();
        }
      },
      'eth-balance',
      0
    );

    this.poorBalance = new Balance(scene, width / 2 + 10 + buttonWidth / 2 + gap, y, null, 'poor-balance', 0);

    this.add(this.xTokenBalance);
    this.add(this.tokenBalance);
    this.add(this.ethBalance);
    this.add(this.poorBalance);

    scene.events.on('s-set-balances', (data) => this.updateValues(data));
    scene.events.on('s-claim-completed', ({ amount }) => {
      this.addedAmountXGang.text = `+${formatter.format(amount)}`;

      scene.tweens.add({
        targets: this.addedAmountXGang,
        y: [y, y + 120],
        alpha: [0, 1],
        duration: 800,
        ease: 'Cubic.out',
        onComplete: () => this.addedAmountXGang.setAlpha(0),
      });
    });

    scene.events.on('s-set-x-token-balance', (data) => this.updateXTokenBalance(data));
    scene.events.on('s-set-poor-token-balance', (data) => this.updatePoorTokenBalance(data));
  }

  updateValues({ ETHBalance, tokenBalance }) {
    this.tokenBalance.updateValue(customFormat(tokenBalance || 0, 1));
    this.ethBalance.updateValue(formatter.format(ETHBalance));
  }

  updateXTokenBalance({ balance }) {
    this.xTokenBalance.updateValue(customFormat(balance || 0, 1));
    if (this.xGangBalance && balance - this.xGangBalance > 0) {
      this.addedAmountXGang.text = `+${customFormat(balance - this.xGangBalance, 1)}`;
      this.scene.tweens.add({
        targets: this.addedAmountXGang,
        y: [this.headerY, this.headerY + 120],
        alpha: [0, 1],
        duration: 800,
        ease: 'Cubic.out',
        onComplete: () => this.addedAmountXGang.setAlpha(0),
      });
    }
    this.xGangBalance = balance;
  }

  updatePoorTokenBalance({ balance }) {
    this.poorBalance.updateValue(customFormat(balance || 0, 1));
    if (this.poorBalance && balance - this.poorTokenBalance > 0) {
      this.addedAmountPoorToken.text = `+${customFormat(balance - this.poorTokenBalance, 3)}`;
      this.scene.tweens.add({
        targets: this.addedAmountPoorToken,
        y: [this.headerY, this.headerY + 120],
        alpha: [0, 1],
        duration: 800,
        ease: 'Cubic.out',
        onComplete: () => this.addedAmountPoorToken.setAlpha(0),
      });
    }
    this.poorTokenBalance = balance;
  }
}

export default Header;
