import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import Button from '../button/Button';

const { width, height } = configs;

class PopupLinkX extends Popup {
  constructor(scene) {
    super(scene, 'popup-link-x', { title: 'Connect to X' });

    this.backBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.displayHeight / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(this.backBtn);

    this.linkXBtn = new Button(
      scene,
      width / 2,
      this.popup.y + 300,
      'button-link-x',
      'button-link-x-pressed',
      () => {
        this.close();
        scene.popupSettings?.open();
      },
      {
        sound: 'button-1',
      }
    );
    this.add(this.linkXBtn);
  }
}

export default PopupLinkX;
