import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { formatTimeDigit } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';

const { width, height } = configs;

class PopupAugmentsPending extends Popup {
  constructor(scene) {
    super(scene, 'popup-tiny', { title: 'Augments Pending' });

    this.cancelBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      'Okay',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.cancelBtn);

    this.requirementText = scene.add
      .text(this.popup.x, this.popup.y - 120, 'Your augments will be available in:', {
        fontSize: fontSizes.large,
        fontFamily: fontFamilies.bold,
        color: colors.black,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.requirementText);

    this.clockIcon = scene.add.image(width / 2 - 200, this.requirementText.y + 120, 'icon-clock');
    this.countdownText = scene.add
      .text(this.clockIcon.x + 50, this.clockIcon.y, '--h --m --s', {
        fontSize: fontSizes.large,
        fontFamily: fontFamilies.bold,
        color: colors.brown,
      })
      .setOrigin(0, 0.5);
    this.add(this.clockIcon);
    this.add(this.countdownText);

    this.comebackText = scene.add
      .text(this.popup.x, this.clockIcon.y + 120, 'Come back later to select', {
        fontSize: fontSizes.large,
        fontFamily: fontFamilies.bold,
        color: colors.black,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.comebackText);

    scene.events.on('s-set-augment-configs', (configs) => {
      const { nextAugmentAt } = configs;
      this.endTimeUnix = nextAugmentAt.toDate().getTime();
      this.countdown();
    });
  }

  cleanup() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  countdown() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.endTimeUnix) {
      this.showEndTime();
      this.interval = setInterval(() => this.showEndTime(), 1000);
    }
  }

  showEndTime() {
    const now = Date.now();
    const diff = this.endTimeUnix - now;

    if (diff <= 0) {
      this.countdownText.setVisible(false);
      clearInterval(this.interval);
      this.interval = null;
      return;
    }

    const diffInSeconds = Math.max(diff / 1000, 0);
    const hours = Math.floor((diffInSeconds % 86400) / 3600);
    const mins = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.round(diffInSeconds % 60);

    this.countdownText.setVisible(true);
    this.countdownText.text = `${formatTimeDigit(hours)}h ${formatTimeDigit(mins)}m ${formatTimeDigit(seconds)}s`;
  }
}

export default PopupAugmentsPending;
