import Phaser from 'phaser';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import environments from '../../../../utils/environments';

const { width, height } = configs;
const { NETWORK_ID } = environments;

class PopupTxnCompletedWarUpgrades extends Popup {
  onCompleted;
  constructor(scene, icon, title, description, { onCompleted, pistolTxnHash, shieldTxnHash }) {
    super(scene, 'popup-small', { title: 'Completed', openOnCreate: true });

    this.onCompleted = onCompleted;
    const startingY = this.popup.y - this.popup.height / 2;
    let iconY = startingY + (title ? 300 : 400);
    let titleY = title ? iconY + 180 : iconY + 80;
    let descriptionY = titleY + 260;
    let viewTxnHashY = descriptionY + 200;

    if (!pistolTxnHash && !shieldTxnHash) {
      // move everything down a bit
      iconY += 100;
      titleY += 100;
      descriptionY += 100;
    } else if (pistolTxnHash && shieldTxnHash) {
      // move everything up a bit
      iconY -= 50;
      titleY -= 50;
      descriptionY -= 50;
      viewTxnHashY -= 20;
    }

    const txnHashOffset = pistolTxnHash && shieldTxnHash ? 50 : 0;
    const pistolTxnHashY = viewTxnHashY - txnHashOffset;
    const shieldTxnHashY = viewTxnHashY + txnHashOffset;

    this.icon = scene.add.sprite(width / 2, iconY, icon);
    this.title = scene.add
      .text(width / 2, titleY, title, {
        fontSize: '90px',
        color: '#29000b',
        fontFamily: 'WixMadeforDisplayExtraBold',
        align: 'center',
      })
      .setOrigin(0.5, 0);
    const descriptionContainer = scene.add.image(width / 2, descriptionY, 'text-container');
    this.description = scene.add
      .text(width / 2, descriptionY, description, {
        fontSize: '52px',
        color: '#7c2828',
        fontFamily: 'WixMadeforDisplayBold',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.viewTxnHashPistol = scene.add
      .image(width / 2, pistolTxnHashY, 'view-pistol-transaction')
      .setVisible(!!pistolTxnHash);
    this.viewTxnHashShield = scene.add
      .image(width / 2, shieldTxnHashY, 'view-shield-transaction')
      .setVisible(!!shieldTxnHash);

    this.add(this.icon);
    this.add(this.title);
    this.add(descriptionContainer);
    this.add(this.description);
    this.add(this.viewTxnHashPistol);
    this.add(this.viewTxnHashShield);

    const buttonGreat = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      this.close,
      'Great',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(buttonGreat);

    this.viewTxnHashPistol
      .setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        if (!this.viewTxnHashPistol.visible) return;
        this.viewTxnHashPistol.setAlpha(0.5);
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
        if (!this.viewTxnHashPistol.visible) return;
        this.viewTxnHashPistol.setAlpha(1);
        window.open(`https://${SCANNER_URL[NETWORK_ID]}/tx/${pistolTxnHash}`);
      });

    this.viewTxnHashShield
      .setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        if (!this.viewTxnHashShield.visible) return;
        this.viewTxnHashShield.setAlpha(0.5);
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
        if (!this.viewTxnHashShield.visible) return;
        this.viewTxnHashShield.setAlpha(1);
        window.open(`https://${SCANNER_URL[NETWORK_ID]}/tx/${shieldTxnHash}`);
      });
  }

  cleanup() {
    this.onCompleted?.();
  }

  updateIcon(newIcon) {
    this.icon.setTexture(newIcon);
  }
}

export const SCANNER_URL = {
  8453: 'basescan.org',
  84531: 'goerli.basescan.org',
  84532: 'sepolia.basescan.org',
  81457: 'blastscan.io',
  168587773: 'testnet.blastscan.io',
};

export default PopupTxnCompletedWarUpgrades;
