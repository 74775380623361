import Popup from './Popup';
import Button from '../button/Button';
import PopupProcessing from './PopupProcessing';
import PopupConfirmStarterPack from './PopupConfirmStarterPack';
import configs from '../../configs/configs';
import { customFormat, formatTimeDigit } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { calculateNextMachineBuyPriceBatch, calculateNextWorkerBuyPriceBatch } from '../../../../utils/formulas';

const { width, height } = configs;

class PopupStarterPack extends Popup {
  packId = 'default';
  endTimeUnix = null;
  available = false;
  poorBalance = 0;
  ethBalance = 0;
  goonsCount = 0;
  gangstersCount = 0;
  originalEthPrice = 0;
  pricePoor = 1;
  priceEth = 0.1;
  priceEthPercent = 0;
  gangster = {};
  goon = {};

  constructor(scene) {
    super(scene, 'popup-starter-pack', { title: 'Discount!', ribbon: 'ribbon-extra' });

    const unitsCounterY = this.popup.y + 145;
    const countdownY = unitsCounterY + 230;

    this.popupBuyProcessing = new PopupProcessing(scene, {
      sound: 'spin-result-sound',
      completedEvent: 's-buy-starter-pack-completed',
      completedIcon: 'icon-buy-starter-pack-done',
      failedIcon: 'icon-buy-starter-pack',
      description: ``,
    });
    scene.add.existing(this.popupBuyProcessing);
    this.popupConfirm = new PopupConfirmStarterPack(scene, this, {
      onConfirm: () => {
        this.popupBuyProcessing.initLoading(`Purchasing beginners pack.\nPlease, wait`);

        scene.events.emit('s-buy-starter-pack', {
          packId: this.packId,
          goonsQuantity: this.goonsCount,
          gangstersQuantity: this.gangstersCount,
        });
      },
    });
    scene.add.existing(this.popupConfirm);

    // title percent
    this.titlePercent = scene.add
      .text(this.popupTitle.x, this.popupTitle.y, '', {
        fontSize: '128px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5)
      .setStroke('#9e0a2e', 12);
    this.titlePercentShadow = scene.add
      .text(this.popupTitle.x, this.popupTitle.y + 5, '', {
        fontSize: '128px',
        color: '#9e0a2e',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5)
      .setStroke('#9e0a2e', 12);
    this.add(this.titlePercentShadow);
    this.add(this.titlePercent);

    this.numberOfGoons = scene.add
      .text(width / 2 - this.popup.width * 0.14, unitsCounterY, ``, {
        fontSize: fontSizes.medium,
        color: colors.brown,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.numberOfGangsters = scene.add
      .text(width / 2 + this.popup.width * 0.16, unitsCounterY, ``, {
        fontSize: fontSizes.medium,
        color: colors.brown,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfGoons);
    this.add(this.numberOfGangsters);

    this.starterPackCountdownText = scene.add
      .text(width / 2 - 190, countdownY, '--d --h --m --s', {
        fontSize: fontSizes.large,
        fontFamily: fontFamilies.bold,
        color: colors.black,
      })
      .setOrigin(0, 0.5);
    this.add(this.starterPackCountdownText);

    this.buttonBuyNow = new BuyNowButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue-med-long',
      'button-blue-med-long-pressed',
      () => {
        this.close();
        this.popupConfirm.open();
      },
      { sound: 'buy', disabledImage: 'button-med-long-disabled' }
    );
    this.add(this.buttonBuyNow);

    scene.events.on('s-set-balances', ({ ETHBalance }) => {
      this.ethBalance = ETHBalance;
      this.updateBtnDisabledStatus();
    });
    scene.events.on('s-set-poor-token-balance', ({ balance }) => {
      this.poorBalance = balance;
      this.updateBtnDisabledStatus();
    });
    scene.events.on('s-set-starter-pack-available', ({ starterPackAvailable }) => {
      this.available = starterPackAvailable;
      if (!starterPackAvailable) this.close();
    });
    scene.events.on('s-set-starter-pack-config', (packsConfig) => {
      const { pricePoor, priceEthPercent, numberOfGoons, numberOfGangsters, availableUntil } = packsConfig[this.packId];
      this.goonsCount = numberOfGoons;
      this.gangstersCount = numberOfGangsters;
      this.priceEthPercent = priceEthPercent;
      this.pricePoor = pricePoor;
      this.endTimeUnix = availableUntil.toDate().getTime();
      this.updatePrices();
      this.countdown();

      this.popupConfirm.updateQuantity({ goonQuantity: numberOfGoons, gangsterQuantity: numberOfGangsters });
      this.numberOfGoons.text = `${customFormat(numberOfGoons, 2)}`;
      this.numberOfGangsters.text = `${customFormat(numberOfGangsters, 2)}`;

      this.setTitle('                  Discount!');
      const titlePercentX = this.popupTitle.x - this.popupTitle.width / 2;
      this.titlePercent.x = titlePercentX;
      this.titlePercentShadow.x = titlePercentX;
      this.titlePercent.text = `${Math.round((1 - priceEthPercent) * 100)}%`;
      this.titlePercentShadow.text = `${Math.round((1 - priceEthPercent) * 100)}%`;
    });
    scene.events.on(
      's-set-machines',
      ({
        balance,
        basePrice,
        targetDailyPurchase,
        pricePower,
        targetPrice,
        totalSold,
        days,
        started,
        startingPrice,
      }) => {
        this.gangster.balance = balance;
        this.gangster.basePrice = basePrice;
        this.gangster.targetDailyPurchase = targetDailyPurchase;
        this.gangster.pricePower = pricePower;
        this.gangster.targetPrice = targetPrice;
        this.gangster.totalSold = totalSold;
        this.gangster.days = days;
        this.gangster.started = !!started;
        this.gangster.startingPrice = startingPrice;

        this.updatePrices();
      }
    );
    scene.events.on(
      's-set-workers',
      ({
        balance,
        basePrice,
        targetDailyPurchase,
        pricePower,
        targetPrice,
        totalSold,
        days,
        started,
        startingPrice,
      }) => {
        this.goon.balance = balance;
        this.goon.basePrice = basePrice;
        this.goon.targetDailyPurchase = targetDailyPurchase;
        this.goon.pricePower = pricePower;
        this.goon.targetPrice = targetPrice;
        this.goon.totalSold = totalSold;
        this.goon.days = days;
        this.goon.started = !!started;
        this.goon.startingPrice = startingPrice;

        this.updatePrices();
      }
    );
    scene.events.on('s-set-market-data', ({ tokenPrice }) => {
      this.tokenPrice = tokenPrice;
      this.updatePrices();
    });
  }

  onOpen() {
    this.scene.events.emit('s-get-market-data');
    this.scene.events.emit('s-get-poor-token-balance');
    this.scene.events.emit('s-get-starter-pack-config');
    this.scene.events.emit('s-enable-machine-sales-tracking');
    this.scene.events.emit('s-enable-worker-sales-tracking');
    this.scene.events.emit('s-get-starter-pack-available', { packId: this.packId });
  }

  cleanup() {
    this.scene.events.emit('s-disable-machine-sales-tracking');
    this.scene.events.emit('s-disable-worker-sales-tracking');

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  showEndTime() {
    const now = Date.now();
    const diff = this.endTimeUnix - now;

    if (diff <= 0) {
      this.starterPackCountdownText.setVisible(false);
      clearInterval(this.interval);
      this.interval = null;
      return;
    }

    const diffInSeconds = Math.max(diff / 1000, 0);
    const days = Math.floor(diffInSeconds / 86400);
    const hours = Math.floor((diffInSeconds % 86400) / 3600);
    const mins = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.round(diffInSeconds % 60);

    this.starterPackCountdownText.setVisible(true);
    this.starterPackCountdownText.text = `${formatTimeDigit(days)}d ${formatTimeDigit(hours)}h ${formatTimeDigit(
      mins
    )}m ${formatTimeDigit(seconds)}s`;
  }

  countdown() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.endTimeUnix) {
      this.interval = setInterval(() => this.showEndTime(), 1000);
    }
  }

  updatePrices() {
    const estimatedGangsterPrice = this.gangster.started
      ? calculateNextMachineBuyPriceBatch(
          this.gangster.totalSold,
          this.gangster.days,
          this.gangster.targetDailyPurchase,
          this.gangster.pricePower,
          this.gangster.targetPrice,
          this.gangster.basePrice,
          this.gangstersCount
        ).total
      : this.gangster.startingPrice * this.gangstersCount;

    const estimatedGoonPrice = this.goon.started
      ? calculateNextWorkerBuyPriceBatch(
          this.goon.totalSold,
          this.goon.days,
          this.goon.targetDailyPurchase,
          this.goon.pricePower,
          this.goon.targetPrice,
          this.goon.basePrice,
          this.goonsCount
        ).total
      : this.goon.startingPrice * this.goonsCount;

    const starterPackPriceToken = estimatedGangsterPrice + estimatedGoonPrice;
    const starterPackPriceEth = starterPackPriceToken * this.tokenPrice;
    this.priceEth = starterPackPriceEth * this.priceEthPercent;
    this.popupConfirm.updatePrices({
      ethPriceText: `${customFormat(this.priceEth, 3)}`,
      poorPriceText: `${customFormat(this.pricePoor, 3)}`,
    });
    this.buttonBuyNow.updatePrices({ ethPrice: this.priceEth, poorPrice: this.pricePoor });
    this.updateBtnDisabledStatus();
  }

  updateBtnDisabledStatus() {
    this.buttonBuyNow.setDisabledState(this.pricePoor > this.poorBalance || this.priceEth > this.ethBalance);
    this.scene.events.emit('update-can-buy-starter-pack', {
      userCanBuy: this.available && !this.buttonBuyNow.disabled,
    });
  }
}

export default PopupStarterPack;

class BuyNowButton extends Button {
  constructor(scene, x, y, defaultImage, pressedImage, onClick, { sound, disabledImage } = {}) {
    super(scene, x, y, defaultImage, pressedImage, onClick, { sound, disabledImage });

    // texts
    this.textStrokeColor = '#0004a0';
    this.buyNowText = scene.add
      .text(0, -55, 'BUY NOW', {
        fontSize: '52px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.buyNowText.setStroke(this.textStrokeColor, 10);
    this.add(this.buyNowText);

    this.poorPrice = scene.add
      .text(40, 30, '+ 0', {
        fontSize: '82px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.poorPrice.setStroke(this.textStrokeColor, 10);
    this.add(this.poorPrice);

    this.ethIcon = scene.add.image(this.poorPrice.x - 50, 30, 'icon-eth').setDisplaySize(82, 82);
    this.poorIcon = scene.add.image(this.defaultImage.width / 2 - 80, 30, 'icon-poor-small').setDisplaySize(82, 82);
    this.ethPrice = scene.add
      .text(this.ethIcon.x - 50, 30, '0', {
        fontSize: '82px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(1, 0.5);
    this.ethPrice.setStroke(this.textStrokeColor, 10);
    this.add(this.ethPrice);
    this.add(this.ethIcon);
    this.add(this.poorIcon);
  }

  updatePrices({ ethPrice, poorPrice }) {
    this.ethPrice.text = `${customFormat(ethPrice, 3)}`;
    this.poorPrice.text = `+ ${customFormat(poorPrice, 1)}`;

    this.poorPrice.x = (this.ethPrice.width + this.ethIcon.width - (this.poorPrice.width + this.poorIcon.width)) / 2;
    this.ethIcon.x = this.poorPrice.x - 50;
    this.ethPrice.x = this.ethIcon.x - 50;
    this.poorIcon.x = this.poorPrice.x + this.poorPrice.width + 40;
  }
}
