import { create } from 'zustand';
import { doc, getDoc } from 'firebase/firestore';
import * as Sentry from '@sentry/react';

import { firestore } from '../configs/firebase.config';
import { getUserWarDeployment } from '../services/gamePlay.service';

const useUserStore = create((set, get) => ({
  initialized: false,
  profile: null,
  gamePlay: null,
  setInitialized: (newInitialized) => set((state) => ({ initialized: newInitialized })),
  setProfile: (newProfile) => set((state) => ({ profile: newProfile })),
  setGamePlay: (newGamePlay) => set((state) => ({ gamePlay: newGamePlay })),
  reloadWarDeployment: async () => {
    try {
      const res = await getUserWarDeployment();

      const gamePlay = get().gamePlay;
      set({ gamePlay: { ...gamePlay, warDeployment: res.data } });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  },
}));

export default useUserStore;
