import { useState } from 'react';
import { Box, Typography, Button, Checkbox, FormGroup, FormControlLabel } from '@mui/material';

import { agreeToS } from '../../services/user.service';

const tosLink = 'https://wiki.gangsterarena.com/terms-of-service';

const Warning = () => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const submit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await agreeToS();
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        width="100vw"
        minHeight="100vh"
        position="absolute"
        sx={{
          zIndex: -1,
          top: 0,
          backgroundImage: {
            xs: 'url(images/bg-login-vertical.webp)',
            sm: 'url(images/bg-login-5x4.webp)',
            md: 'url(images/bg-login.webp)',
          },
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
      <Box
        minHeight="100vh"
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        bgcolor="rgba(0, 0, 0, 0.1)">
        <Box flex={1} display="flex" flexDirection="column" justifyContent="center" gap={3}>
          <Box
            flex={1}
            mx="auto"
            width={{ xs: '100%', sm: '400px' }}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            gap={2}>
            <Box id="twitter-link-container">
              <Box p={2} bgcolor="#fbf3e7" display="flex" flexDirection="column" gap={3}>
                <Box position="relative">
                  <Typography
                    fontWeight={600}
                    align="center"
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => window.open(tosLink)}>
                    Terms of Services
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img src="/images/logo.svg" width="60%" alt="logo" />
                </Box>
                <Box px={4} display="flex" flexDirection="column" alignItems="center" gap={1.2}>
                  <Typography fontWeight={600} align="center" color="error">
                    Playing on or managing multi accounts is not allowed and may result in full reward removal.
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="success"
                          disabled={loading}
                          checked={checked}
                          onChange={(e) => !loading && setChecked(e.target.checked)}
                        />
                      }
                      label={<Typography fontSize={14}>I agree to the Terms of Service</Typography>}
                    />
                  </FormGroup>
                </Box>
                <Box display="flex" flexDirection="column" mt={2} gap={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={!checked}
                    onClick={submit}
                    sx={{
                      borderRadius: '4%/24%',
                      backgroundColor: 'black',
                      backgroundImage: 'url(/images/button-black.png)',
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat',
                      aspectRatio: 5.62 / 1,
                      boxShadow: 'none',
                      transition: 'all ease 0.3s',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: 'black',
                        backgroundImage: 'url(/images/button-black-pressed.png)',
                      },
                      '&:disabled': {
                        opacity: 0.7,
                      },
                    }}>
                    <Box
                      width="100%"
                      position="relative"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={2}>
                      <Typography align="center" fontSize={20} fontWeight={800} sx={{ textTransform: 'none' }}>
                        {loading ? 'Loading' : 'Continue'}
                      </Typography>
                      {checked && (
                        <img
                          src="/images/next-arrow.png"
                          alt="next"
                          width="5%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            right: 0,
                            transform: 'translateY(-50%)',
                          }}
                        />
                      )}
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Warning;
