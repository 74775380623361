import Phaser from 'phaser';

import { AugmentItem } from './PopupAugments';
import TextButton from '../button/TextButton';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { formatTimeDigit } from '../../../../utils/numbers';
import configs from '../../configs/configs';

const { width } = configs;

class PopupSelectAugmentComplete extends Phaser.GameObjects.Container {
  iconY = configs.height / 2 - 200;
  iconX = configs.width / 2;

  constructor(scene) {
    super(scene, 0, 0);
    this.setVisible(false);

    this.sound = scene.sound.add('spin-result-sound', { loop: false });

    this.background = scene.add.rectangle(0, 0, configs.width, configs.height, 0x260343, 0.8).setOrigin(0, 0);
    this.add(this.background);

    this.glowFx = scene.add.image(this.iconX, this.iconY, 'spin-reward-glow-fx').setOrigin(0.5, 0.5);
    this.add(this.glowFx);

    this.timerContainer = scene.add.image(width / 2, this.iconY + 330, 'timer-container');
    this.countdownText = scene.add
      .text(width / 2 - 70, this.timerContainer.y, 'Next War', {
        fontSize: fontSizes.medium,
        fontFamily: fontFamilies.bold,
        color: colors.black,
      })
      .setOrigin(0, 0.5);

    this.wonText = scene.add
      .text(this.iconX, this.iconY + 500, 'YOU HAVE AN AUGMENT', {
        fontSize: '64px',
        fontFamily: fontFamilies.extraBold,
        color: '#fff',
      })
      .setOrigin(0.5, 0.5);
    this.wonText.setStroke('#591C9A', 10);
    this.add(this.wonText);

    this.rewardText = scene.add
      .text(this.iconX, this.wonText.y + 120, '0', {
        fontSize: '116px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFDD1D',
      })
      .setOrigin(0.5, 0.5);
    this.rewardText.setStroke('#591C9A', 20);
    this.add(this.rewardText);

    this.claimBtn = new TextButton(
      scene,
      this.iconX,
      this.rewardText.y + 300,
      'button-green',
      'button-green-pressed',
      () => this.close(),
      'Great',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.claimBtn);

    this.setDepth(5);
  }

  open() {
    window.poof?.();
    this.setVisible(true);
    this.sound.play();
  }

  close() {
    try {
      this.cleanup?.()?.catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }
    this.setVisible(false);
  }

  cleanup() {
    this.sound.stop();

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  showReward(augment) {
    const { name, type } = augment;

    this.rewardText.text = name;

    if (this.augmentItem) this.augmentItem.destroy();
    this.augmentItem = new AugmentItem(this.scene, width / 2, this.iconY, augment);
    this.add(this.augmentItem);

    this.timerContainer.setVisible(!type.includes('instant'));
    this.countdownText.setVisible(!type.includes('instant'));
    this.add(this.timerContainer);
    this.add(this.countdownText);

    this.open();
  }

  countdown() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.endTimeUnix) {
      this.showEndTime();
      this.interval = setInterval(() => this.showEndTime(), 1000);
    }
  }

  showEndTime() {
    const now = Date.now();
    const diff = this.endTimeUnix - now;

    if (diff <= 0) {
      this.countdownText.setVisible(false);
      clearInterval(this.interval);
      this.interval = null;
      return;
    }

    const diffInSeconds = Math.max(diff / 1000, 0);
    const hours = Math.floor((diffInSeconds % 86400) / 3600);
    const mins = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.round(diffInSeconds % 60);

    this.countdownText.setVisible(true);
    this.countdownText.text = `${formatTimeDigit(hours)}h ${formatTimeDigit(mins)}m ${formatTimeDigit(seconds)}s`;
  }
}

export default PopupSelectAugmentComplete;
