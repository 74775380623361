import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { fontFamilies } from '../../../../utils/styles';

const { width, height } = configs;

class PopupLinkingMetamask extends Popup {
  constructor(scene) {
    super(scene, 'popup-small', { noCloseBtn: true, noBackgroundClick: true });

    this.backBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.displayHeight / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(this.backBtn);

    this.note = scene.add
      .text(
        this.popup.x,
        this.popup.y - this.popup.height / 2 + 200,
        'Connect metamask to link your account.\n We recommend doing this on desktop.\nLinked wallets cannot by unlinked.',
        {
          fontSize: '48px',
          fontFamily: fontFamilies.bold,
          color: '#29000B',
          align: 'center',
        }
      )
      .setOrigin(0.5, 0.5);
    this.add(this.note);

    this.text = scene.add
      .text(this.popup.x, this.popup.y + this.popup.height / 2 - 250, 'Connecting Metamask...', {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#29000B',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.text);

    this.description = scene.add
      .text(this.popup.x, this.text.y + 50, '', {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#29000B',
      })
      .setOrigin(0.5, 0.5);
    this.description.setVisible(false);
    this.add(this.description);

    this.image = scene.add.image(this.popup.x, this.popup.y, 'metamask').setOrigin(0.5, 0.5);
    this.image.setScale(3);
    this.add(this.image);

    scene.events.on('s-signing-wagmi-wallet', () => {
      this.text.text = 'Signing Metamask...';
    });

    scene.events.on('s-link-wagmi-wallet-success', () => {
      this.text.text = 'Link Metamask successfully';
    });

    scene.events.on('s-link-wagmi-wallet-error', ({ message }) => {
      this.text.text = 'Link Metamask failed';
      if (message) {
        this.description.text = message;
        this.description.setVisible(true);
      }
    });
  }

  cleanup() {
    this.text.text = 'Connecting Metamask...';
    this.description.text = '';
    this.description.setVisible(false);
  }
}

export default PopupLinkingMetamask;
