import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { customFormat } from '../../../../utils/numbers';

const { width, height } = configs;
const largeBlackBold = {
  fontSize: fontSizes.large,
  color: colors.black,
  fontFamily: fontFamilies.bold,
};

const iconX = width / 2 - 250;
const iconOffset = 80;

class PopupConfirmStarterPack extends Popup {
  gas = 0;
  goonQuantity = 0;
  gangsterQuantity = 0;

  constructor(scene, parentModal, { onConfirm, onOpen, onClose }) {
    super(scene, 'popup-tiny', { title: 'Buy Beginners Pack' });
    this.onOpen = onOpen;
    this.onClose = onClose;

    const startingY = this.popup.y - this.popup.height / 2;
    const areYouSureY = startingY + 150;
    const mainTextY = areYouSureY + 230;

    const areYouSure = scene.add
      .text(width / 2, areYouSureY, `Are you sure you want to buy:`, {
        ...largeBlackBold,
        align: 'center',
      })
      .setOrigin(0.5, 0);
    this.add(areYouSure);
    const bg = scene.add.image(width / 2, mainTextY, 'container-short');
    this.add(bg);

    const forText = scene.add.text(width / 2 - 60, mainTextY, `for`, largeBlackBold).setOrigin(0, 0.5);
    this.add(forText);

    this.goon = scene.add.container();
    this.gangster = scene.add.container();
    this.iconGoon = scene.add.image(iconX - iconOffset, mainTextY, 'icon-goon-medium').setOrigin(0.5, 0.5);
    this.iconGangster = scene.add.image(iconX + iconOffset, mainTextY, 'icon-gangster-medium').setOrigin(0.5, 0.5);
    this.textGoon = scene.add
      .text(this.iconGoon.x + 10, mainTextY, '', {
        fontSize: '56px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setStroke('#6F0132', 10);
    this.textGangster = scene.add
      .text(this.iconGangster.x + 10, mainTextY, '', {
        fontSize: '56px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setStroke('#6F0132', 10);
    this.add(this.goon);
    this.add(this.gangster);
    this.goon.add(this.iconGoon);
    this.goon.add(this.textGoon);
    this.gangster.add(this.iconGangster);
    this.gangster.add(this.textGangster);

    this.plus = scene.add
      .text(forText.x + 120, mainTextY, '+', {
        fontSize: fontSizes.large,
        color: colors.brown,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.ethPrice = scene.add
      .text(this.plus.x + 60, mainTextY - 45, '', {
        fontSize: fontSizes.large,
        color: colors.brown,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.poorPrice = scene.add
      .text(this.plus.x + 60, mainTextY + 45, '', {
        fontSize: fontSizes.large,
        color: colors.brown,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.iconEth = scene.add
      .image(this.plus.x + this.popup.width * 0.28, this.ethPrice.y, 'icon-eth')
      .setDisplaySize(80, 80)
      .setOrigin(0.5, 0.5);
    this.iconPoor = scene.add
      .image(this.plus.x + this.popup.width * 0.28, this.poorPrice.y, 'icon-poor-small')
      .setDisplaySize(80, 80)
      .setOrigin(0.5, 0.5);
    this.add(this.plus);
    this.add(this.ethPrice);
    this.add(this.poorPrice);
    this.add(this.iconEth);
    this.add(this.iconPoor);

    const buttonNo = new TextButton(
      scene,
      width / 2 - this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        parentModal.open();
      },
      'No',
      { fontSize: '82px', sound: 'close' }
    );
    this.buttonYes = new TextButton(
      scene,
      width / 2 + this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        onConfirm();
        this.close();
      },
      'Yes',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.add(buttonNo);
    this.add(this.buttonYes);
  }

  updateQuantity({ goonQuantity, gangsterQuantity }) {
    this.goonQuantity = goonQuantity;
    this.gangsterQuantity = gangsterQuantity;

    this.textGoon.text = customFormat(goonQuantity, 2);
    this.textGangster.text = customFormat(gangsterQuantity, 2);

    this.goon.setVisible(Boolean(goonQuantity));
    this.gangster.setVisible(Boolean(gangsterQuantity));

    if (goonQuantity * gangsterQuantity === 0) {
      const visibleItem = goonQuantity ? this.iconGoon : this.iconGangster;
      visibleItem.setX(iconX);
    } else {
      this.iconGoon.setX(iconX - iconOffset);
      this.iconGangster.setX(iconX + iconOffset);
    }

    this.textGoon.setX(this.iconGoon.x + 10);
    this.textGangster.setX(this.iconGangster.x + 10);
  }
  updatePrices({ ethPriceText, poorPriceText }) {
    this.ethPrice.text = ethPriceText;
    this.poorPrice.text = poorPriceText;
    this.iconEth.x = this.ethPrice.x + this.ethPrice.width + 50;
    this.iconPoor.x = this.poorPrice.x + this.poorPrice.width + 50;
  }
  updateIconRight(texture) {
    this.iconRight.setTexture(texture);
  }
}

export default PopupConfirmStarterPack;
