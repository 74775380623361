import { useEffect, useState, useRef, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography, alpha } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { submitOauthData } from '../../services/twitter.service';
import useUserStore from '../../stores/user.store';

const VerifyTwitter = () => {
  const loaded = useRef();
  const navigate = useNavigate();
  const profile = useUserStore((state) => state.profile);
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const oauth_token = searchParams.get('oauth_token');
  const oauth_verifier = searchParams.get('oauth_verifier');
  const denied = searchParams.get('denied');

  const submitTwitter = async () => {
    if (profile.socials?.twitter?.verified) {
      setStatus('success');
      return;
    }
    if (oauth_token && oauth_verifier) {
      try {
        await submitOauthData({ oauth_token, oauth_verifier, csrfToken: localStorage.getItem('CSRF_TOKEN') });
        setStatus('success');
        setError('');
      } catch (err) {
        setStatus('error');
        setError(err.message);
      }

      localStorage.removeItem('CSRF_TOKEN');
    }
  };

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      if (denied) {
        navigate('/');
      } else {
        submitTwitter();
      }
    }
  }, [oauth_token, oauth_verifier, denied]);

  const statusInfo = useMemo(() => {
    if (status === 'loading')
      return { text: 'Verifying your Twitter...', icon: <CircularProgress color="success" size={48} /> };
    if (status === 'success')
      return { text: 'Twitter verified!', icon: <CheckCircleRoundedIcon color="success" sx={{ fontSize: 60 }} /> };
    return { text: error, icon: <CancelRoundedIcon color="error" sx={{ fontSize: 60 }} /> };
  }, [status, error]);

  return (
    <>
      <Box
        width="100vw"
        minHeight="100vh"
        position="absolute"
        sx={{
          zIndex: -1,
          top: 0,
          backgroundImage: {
            xs: 'url(/images/bg-login-vertical.webp)',
            sm: 'url(/images/bg-login-5x4.webp)',
            md: 'url(/images/bg-login.webp)',
          },
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
      <Box
        minHeight="100vh"
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        bgcolor="rgba(0, 0, 0, 0.6)">
        <Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={3}>
          <Box
            p={2}
            width="400px"
            maxWidth="90vw"
            bgcolor={alpha('#000', 0.8)}
            borderRadius={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <Box py={1}>{statusInfo.icon}</Box>
            <Typography fontSize={20} fontWeight={700} align="center" color="white">
              {statusInfo.text}
            </Typography>
            {status !== 'loading' && (
              <Typography
                fontSize={14}
                fontWeight={500}
                color="white"
                align="center"
                sx={{
                  mt: 1,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => (window.location.href = window.location.origin)}>
                Back to home
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifyTwitter;
