import Popup from './Popup';
import PopupProcessing from './PopupProcessing';
import PopupConfirm, { icon1Gap } from './PopupConfirm';
import TextButton from '../button/TextButton';
import Button from '../button/Button';
import configs from '../../configs/configs';
import { estimateNumberOfThugCanBuy, calculateNextThugBuyPriceBatch } from '../../../../utils/formulas';
import { customFormat, formatter } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { fibonacis, maxStepCount, maxStepSizeIndex } from '../../../../utils/constants';

const { width, height } = configs;
const DEFAULT_QUANTITY = 1;
const INTERVAL = 100;
const largeBlackExtraBold = {
  fontSize: fontSizes.large,
  color: colors.black,
  fontFamily: fontFamilies.extraBold,
};

const smallGreenBold = { fontSize: fontSizes.small, color: colors.green, fontFamily: fontFamilies.bold };

class PopupBuyThug extends Popup {
  status = null;
  numberOfThugs = 0;
  thug = {};
  networth = 0;
  networthBase = 0;
  networthPerDay = 0;
  poorTokenBalance = 0;
  basePrice = 0;
  maxPerBatch = 10;
  targetDailyPurchase = 1;
  pricePower = 0;
  targetPrice = 0;
  totalSold = 0;
  days = 1;
  quantity = DEFAULT_QUANTITY;
  onCompleted;
  purchaseToken = 'POOR';
  isTrackingSales = false;
  started = false;
  startingPrice = 0;
  rateIncrease = 0;
  stepSizeIndex = 0;
  stepCount = 0;
  interval = null;

  constructor(scene, { onCompleted, ...configs } = {}) {
    super(scene, 'popup-buy-thug', { title: 'Buy Thug', ...configs });
    this.scene = scene;
    this.onCompleted = onCompleted;
    const numberOfThugsY = this.popup.y - this.popup.height / 2 + 215;
    const rateY = numberOfThugsY + 310;
    const networthY = this.popup.y - 20;
    const counterY = this.popup.y + this.popup.height / 2 - 265;
    const minusBtnX = this.popup.x - this.popup.width / 2 + 320;

    this.popupBuyProcessing = new PopupProcessing(scene, {
      sound: 'minion',
      completedEvent: 's-buy-thug-completed',
      completedIcon: 'icon-thug-buy-done',
      failedIcon: 'icon-thug-buy-fail',
      description: `Buying Thug.\nPlease, wait`,
      onCompleted,
    });
    scene.add.existing(this.popupBuyProcessing);
    this.popupConfirm = new PopupConfirm(scene, this, {
      title: 'Buy Thug',
      action: 'buy',
      icon1: 'icon-thug-medium',
      icon2: 'icon-poor-small',
      onConfirm: () => {
        if (!this.quantity) return;
        this.popupBuyProcessing.initLoading(`Buying Thug.\nPlease, wait`);

        scene.events.emit('s-buy-thug', { amount: this.quantity, token: this.purchaseToken });
      },
      onOpen: () => scene.events.emit('s-enable-thug-sales-tracking'),
      onClose: () => scene.events.emit('s-disabled-thug-sales-tracking'),
    });
    scene.add.existing(this.popupConfirm);
    this.popupConfirm.updateTextLeft(`1${icon1Gap}unit`);

    this.buyBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        this.popupConfirm.open();
      },
      'Buy',
      { fontSize: '82px', sound: 'buy' }
    );
    this.add(this.buyBtn);
    this.buyBtn.setDisabledState(true);

    this.levelTitle = scene.add
      .text(this.popup.x + 80, this.popup.y - this.popup.height / 2 + 215, 'Thugs:', {
        fontSize: fontSizes.large,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.levelTitle);

    this.numberOfThugsText = scene.add
      .text(this.popup.x + 150, numberOfThugsY, '', {
        fontSize: fontSizes.extraLarge,
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfThugsText);

    this.rateText = scene.add.text(this.popup.x + 320, rateY, '0', largeBlackExtraBold).setOrigin(1, 0);
    this.rateIncreaseText = scene.add
      .text(this.popup.x + this.popup.width * 0.4, rateY + 80, '+0 /d', smallGreenBold)
      .setOrigin(1, 0);
    this.add(this.rateText);
    this.add(this.rateIncreaseText);

    this.networthText = scene.add.text(this.popup.x + 380, networthY, '0', largeBlackExtraBold).setOrigin(1, 0);
    this.networthIncreaseText = scene.add
      .text(this.popup.x + this.popup.width * 0.4, networthY + 80, '+0', {
        fontSize: fontSizes.small,
        color: colors.green,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0);
    this.add(this.networthText);
    this.add(this.networthIncreaseText);

    this.minusBtn = new TextButton(
      scene,
      minusBtnX,
      counterY,
      'button-square',
      'button-square-pressed',
      () => {
        if (this.quantity > DEFAULT_QUANTITY) {
          this.quantity--;
          this.updateValues();
        }
      },
      '-',
      {
        disabledImage: 'button-square-disabled',
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.quantity > DEFAULT_QUANTITY) {
              this.quantity = Math.max(0, this.quantity - stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.minusBtn);

    this.plusBtn = new TextButton(
      scene,
      minusBtnX + 350,
      counterY,
      'button-square',
      'button-square-pressed',
      () => {
        if (this.quantity < this.maxPerBatch) {
          this.quantity++;
          this.updateValues();
        }
      },
      '+',
      {
        disabledImage: 'button-square-disabled',
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.quantity < this.maxPerBatch) {
              this.quantity = Math.min(this.maxPerBatch, this.quantity + stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.plusBtn);

    this.quantityText = scene.add.text(minusBtnX + 170, counterY, this.quantity, {
      fontSize: '60px',
      fontFamily: fontFamilies.extraBold,
      color: '#7C2828',
    });
    this.quantityText.setOrigin(0.5, 0.5);
    this.add(this.quantityText);

    const priceTextX = this.popup.x + 160;
    this.priceText = scene.add.text(priceTextX, counterY, '0', largeBlackExtraBold).setOrigin(0, 0.5);
    this.add(this.priceText);
    this.insufficientBalance = scene.add
      .text(priceTextX, counterY + 30, 'Insufficient $POOR', {
        fontSize: fontSizes.small,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, -1)
      .setVisible(false);
    this.add(this.insufficientBalance);

    this.coin = scene.add
      .image(this.priceText.x + this.priceText.width + 10, counterY, 'icon-poor-small')
      .setOrigin(0, 0.5);
    this.add(this.coin);

    scene.events.on('s-buy-thug-completed', () => {
      this.quantity = DEFAULT_QUANTITY;
      this.updateValues();
    });

    scene.events.on('s-set-season-status', ({ status }) => {
      this.status = status;
      if (status !== 'open') {
        this.buyBtn.setDisabledState(true);
      }
    });

    scene.events.on('s-set-poor-token-balance', ({ balance }) => {
      if (!this.visible) return;
      this.poorTokenBalance = balance;
      this.updateValues();
    });

    scene.events.on(
      's-set-thugs',
      ({
        numberOfThugs,
        basePrice,
        maxPerBatch,
        targetDailyPurchase,
        pricePower,
        targetPrice,
        totalSold,
        days,
        networthBase,
        networthPerDay,
        dailyReward,
        isTrackingSales,
        started,
        startingPrice,
      }) => {
        this.basePrice = basePrice;
        this.maxPerBatch = maxPerBatch;
        this.targetDailyPurchase = targetDailyPurchase;
        this.pricePower = pricePower;
        this.targetPrice = targetPrice;
        this.totalSold = totalSold;
        this.days = days;
        this.numberOfThugs = numberOfThugs;
        this.networthBase = networthBase;
        this.networthPerDay = networthPerDay;
        this.isTrackingSales = isTrackingSales;
        this.started = !!started;
        this.startingPrice = startingPrice;
        this.rateIncrease = dailyReward;

        this.numberOfThugsText.text = `${numberOfThugs?.toLocaleString()}`;
        this.levelTitle.x = this.popup.x + 80 - this.numberOfThugsText.width / 2 + 5;
        this.numberOfThugsText.x =
          this.levelTitle.x + this.levelTitle.width / 2 + this.numberOfThugsText.width / 2 + 10;

        this.rateText.text = `${formatter.format(numberOfThugs * dailyReward)}`;
        this.updateValues();
      }
    );

    scene.events.on('s-set-networth', ({ networth }) => {
      this.networth = networth;
      this.networthText.text = `${networth.toLocaleString()}`;
      this.updateValues();
    });
  }

  offInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  onInterval() {
    this.offInterval();
    this.interval = setInterval(() => {
      this.scene.events.emit('s-get-thugs');
    }, 60 * 1000);
  }

  onOpen() {
    this.scene.events.emit('s-get-networth');
    this.scene.events.emit('s-get-poor-token-balance');
    this.scene.events.emit('s-enable-thug-sales-tracking');
    this.onInterval();
  }

  cleanup() {
    this.onCompleted?.();
    this.scene.events.emit('s-disabled-thug-sales-tracking');
    this.offInterval();
  }

  updateValues() {
    this.networthIncreaseText.text = `+${(this.networthBase * this.quantity).toLocaleString()} instant & +${(
      this.networthPerDay * this.quantity
    ).toLocaleString()} per day`;

    this.rateIncreaseText.text = `+${(this.rateIncrease * this.quantity).toLocaleString()} /d`;

    this.poorTokenMaxPurchase = estimateNumberOfThugCanBuy(
      this.poorTokenBalance,
      this.totalSold,
      this.days,
      this.targetDailyPurchase,
      this.pricePower,
      this.targetPrice,
      this.basePrice,
      this.maxPerBatch
    );
    const estimatedPrice = this.started
      ? calculateNextThugBuyPriceBatch(
          this.totalSold,
          this.days,
          this.targetDailyPurchase,
          this.pricePower,
          this.targetPrice,
          this.basePrice,
          this.quantity
        ).total
      : this.startingPrice;

    this.quantityText.text = `${this.quantity}`;
    this.popupConfirm.updateTextLeft(`${this.quantity}${icon1Gap}unit${this.quantity > 1 ? 's' : ''}`);
    this.popupConfirm.updateTextRight(formatter.format(estimatedPrice.toPrecision(3)));
    this.priceText.text = `${customFormat(estimatedPrice, 1)}`;
    this.coin.x = this.priceText.x + this.priceText.width + 10;

    const maxPurchase = this.poorTokenMaxPurchase;
    const insufficientBalance = this.quantity > maxPurchase;
    this.insufficientBalance.setVisible(insufficientBalance);
    this.buyBtn.setDisabledState(this.status !== 'open' || insufficientBalance || !this.isTrackingSales);
  }
}

export default PopupBuyThug;
