import Button from '../button/Button';

const buttonSize = 186;

class RankButton extends Button {
  constructor(scene, x, y, defaultImage, pressedImage, onClick, { sound } = {}) {
    super(scene, x, y, defaultImage, pressedImage, onClick, { sound });
    this.scene = scene;

    this.valueText = scene.add
      .text(0, buttonSize / 4 + 10, `#-`, {
        fontSize: '42px',
        fontFamily: 'WixMadeforDisplayExtraBold',
        color: '#7C2828',
      })
      .setOrigin(0.5, 0.5);

    this.valueText.align = 'center';

    this.add(this.valueText);

    scene.events.on('s-set-rank', ({ rank }) => {
      if (rank) this.valueText.text = `#${rank}`;
    });

    this.interval = setInterval(() => this.requestRank(), 60 * 1000);
  }

  requestRank() {
    this.scene.events.emit('s-get-rank');
  }

  cleanup() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}

export default RankButton;
