import Phaser from 'phaser';

import Button from '../button/Button';
import { fontFamilies } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';

class AuctionItem extends Phaser.GameObjects.Container {
  constructor(scene, { x, y, item }) {
    super(scene);

    this.container = scene.add.image(x, y, 'auction-item-container').setOrigin(0, 0);
    this.add(this.container);

    const centerX = this.container.x + this.container.width / 2;
    const { id, subId, name, value, iconImg, valueImg, quantity } = item;

    this.image = scene.add.image(centerX, y + 358, iconImg).setOrigin(0.5, 1);
    this.add(this.image);

    this.name = scene.add
      .text(centerX, this.image.y + 40, name, {
        fontSize: '60px',
        fontFamily: fontFamilies.extraBold,
        color: '#29000b',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.name);

    this.valueContainer = scene.add
      .image(centerX, this.name.y + 115, 'auction-item-value-container')
      .setOrigin(0.5, 0.5);
    this.add(this.valueContainer);

    this.icon = scene.add.image(this.valueContainer.x, this.valueContainer.y, valueImg).setOrigin(0.5, 0.5);

    this.value = scene.add
      .text(this.valueContainer.x, this.valueContainer.y, `${formatter.format(value)}`, {
        fontSize: '64px',
        fontFamily: fontFamilies.extraBold,
        color: '#7d2e00',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);

    this.icon.x = this.valueContainer.x - (this.icon.width + this.value.width + 20) / 2 + this.icon.width / 2;
    this.value.x = this.icon.x + this.icon.width / 2 + 20 + this.value.width / 2;

    this.add(this.icon);
    this.add(this.value);

    this.available = scene.add
      .text(centerX, this.valueContainer.y + 90, `${quantity} available`, {
        fontSize: '40px',
        fontFamily: fontFamilies.bold,
        color: '#7d2e00',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.available);

    this.infoButton = new Button(
      scene,
      this.container.x + this.container.width - 50,
      this.container.y + 50,
      'button-info',
      'button-info-pressed',
      () => {
        scene.popupAuctionItemHistory?.show({ subId, title: item.name });
      },
      { sound: 'open' }
    );
    this.add(this.infoButton);

    this.bidBtn = new Button(
      scene,
      centerX,
      this.available.y + 130,
      'button-bid',
      'button-bid-pressed',
      () => {
        scene?.popupAuctionBidding?.bid(item);
      },
      { sound: 'button-1' }
    );
    this.add(this.bidBtn);
  }

  destroyAll() {
    this.removeAll();
    this.container.destroy();
    this.image.destroy();
    this.name.destroy();
    this.valueContainer.destroy();
    this.icon.destroy();
    this.value.destroy();
    this.infoButton.destroy();
    this.available.destroy();
    this.bidBtn.destroy();
    this.destroy();
  }
}

export default AuctionItem;
