import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { fontFamilies, fontSizes, colors } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';

const { width, height } = configs;

const style = {
  fontSize: fontSizes.large,
  color: colors.black,
  fontFamily: fontFamilies.bold,
  align: 'left',
};

class PopupWarUpgradeExplain extends Popup {
  equipmentSpendableIncomeHours = 0;
  maxSpendable = 0;
  amountSpentForEquipmentNextWar = 0;

  constructor(scene) {
    super(scene, 'popup-mini', { title: 'Upgrades', titleIcon: 'icon-info', noCloseBtn: true });

    this.backBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        scene.popupWarUpgrades?.open();
      },
      'Back',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    this.text = scene.add
      .text(
        this.popup.x - this.popup.width / 2 + this.popup.width * 0.05,
        this.popup.y - this.popup.height / 2 + 170,
        'You have spent ---',
        style
      )
      .setOrigin(0, 0);
    this.add(this.text);

    this.text1 = scene.add
      .text(
        this.popup.x - this.popup.width / 2 + this.popup.width * 0.05,
        this.text.y + 80,
        'Max spend per war is ---',
        style
      )
      .setOrigin(0, 0);
    this.add(this.text1);

    this.text2 = scene.add
      .text(this.text1.x, this.text1.y + 80, '(Current xGOLD earning over -- hrs)', style)
      .setOrigin(0, 0);
    this.add(this.text2);

    this.text3 = scene.add
      .text(this.text2.x, this.text2.y + 170, 'Cost of pistols & shields go up with\neach purchase.', style)
      .setOrigin(0, 0);
    this.add(this.text3);

    scene.events.on(
      's-set-war-upgrade-explain',
      ({ equipmentSpendableIncomeHours, maxSpendable, amountSpentForEquipmentNextWar }) => {
        this.equipmentSpendableIncomeHours = equipmentSpendableIncomeHours;
        this.maxSpendable = maxSpendable;
        this.amountSpentForEquipmentNextWar = amountSpentForEquipmentNextWar;
        this.updateValues();
      }
    );
  }

  onOpen() {
    this.scene.events.emit('s-get-war-upgrade-explain');
  }

  updateValues() {
    this.text.text = `You have spent ${formatter.format(this.amountSpentForEquipmentNextWar)}`;
    this.text1.text = `Max spend per war is ${formatter.format(this.maxSpendable)}`;
    this.text2.text = `(Current xGOLD earning over ${this.equipmentSpendableIncomeHours} hrs).`;
  }
}

export default PopupWarUpgradeExplain;
