import Phaser from 'phaser';

import Button from '../button/Button';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';

class Balance extends Phaser.GameObjects.Container {
  constructor(scene, x, y, onClickAdd, img, value) {
    super(scene, 0, 0);

    this.container = scene.add.image(x, y, img).setOrigin(0.5, 0.5);
    this.valueText = scene.add
      .text(x + 50, y - 5, value, {
        fontSize: fontSizes.medium,
        fontFamily: fontFamilies.extraBold,
        color: colors.brown,
      })
      .setOrigin(0.5, 0.5);

    this.add(this.container);
    this.add(this.valueText);

    if (onClickAdd) {
      this.addButton = new Button(
        scene,
        x - this.container.width / 2 + 100,
        y + this.container.height / 2 - 20,
        'button-add-mini',
        'button-add-mini-pressed',
        onClickAdd,
        { sound: 'button-2' }
      );
      this.add(this.addButton);

      // click on whole container not just the button
      this.container
        .setInteractive()
        .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, (pointer, localX, localY, event) => {
          onClickAdd();
        });
    }
  }

  updateValue(newValue) {
    this.valueText.text = newValue;
  }
}

export default Balance;
