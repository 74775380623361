import api from './api';

export const getAuctionItems = () => api.get('/api/v1/auctions/items');

export const getAuctionEndTime = () => api.get('/api/v1/auctions/end-time-unix');

export const createBidding = (data) => api.post('/api/v1/auctions/biddings', data);

export const getBiddingHistory = () => api.get('/api/v1/auctions/bidding-history');

export const getAuctionItemHistory = ({ subId }) => api.get(`/api/v1/auctions/item-history/${subId}`);

export const getAuctionItemHistoryDetail = ({ subId, recordId }) =>
  api.get(`/api/v1/auctions/item-history/${subId}/${recordId}`);
