import Button from './Button';
import { fontFamilies } from '../../../../utils/styles';
import { customFormat } from '../../../../utils/numbers';

const formatTimeDigit = (digit) => (digit < 10 ? `0${digit}` : `${digit}`);

class DisabledClaimButton extends Button {
  interval;

  constructor(scene, x, y) {
    super(scene, x, y, 'button-blue', 'button-blue', () => {});

    this.text = scene.add
      .text(0, -40, 'Claimed', {
        fontSize: '82px',
        color: '#5895fd',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(0.5, 0.5);

    this.countdownText = scene.add
      .text(0, 35, '', {
        fontSize: '60px',
        color: '#fff',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(0.5, 0.5);
    this.countdownText.setStroke('#0004A0', 3);

    this.nextClaimContainer = scene.add.image(0, 95, 'next-claim-container').setOrigin(0.5, 0.5);
    this.rewardText = scene.add
      .text(this.nextClaimContainer.x, this.nextClaimContainer.y, 'NEXT: ', {
        fontSize: '32px',
        fontFamily: fontFamilies.extraBold,
        color: '#fff',
      })
      .setOrigin(0.5, 0.5);
    this.coinIcon = scene.add
      .image(this.rewardText.x + this.rewardText.width / 2 + 40, this.nextClaimContainer.y, 'icon-xtoken-tiny')
      .setOrigin(0.5, 0.5);

    this.add(this.text);
    this.add(this.countdownText);
    this.add(this.nextClaimContainer);
    this.add(this.rewardText);
    this.add(this.coinIcon);

    scene.events.on('s-set-claim-time', ({ claimGapInSeconds, lastClaimTime }) => {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }

      if (!claimGapInSeconds || !lastClaimTime) return;
      this.interval = setInterval(() => {
        const nextClaimTime = lastClaimTime + claimGapInSeconds * 1000;
        const now = Date.now();

        if (now >= nextClaimTime) {
          clearInterval(this.interval);
          this.countdownText.text = `00:00:00`;
          scene.events.emit('s-get-claimable-status');
        } else {
          const diffInSeconds = (nextClaimTime - now) / 1000;
          const hours = Math.floor(diffInSeconds / 3600);
          const mins = Math.floor((diffInSeconds % 3600) / 60);
          const seconds = Math.round(diffInSeconds % 60);
          const time = `${formatTimeDigit(hours)}:${formatTimeDigit(mins)}:${formatTimeDigit(seconds)}`;
          this.countdownText.text = time;
        }
      }, 1000);
    });

    scene.events.on('s-set-claimable-reward', ({ reward }) => {
      this.rewardText.text = `NEXT: ${customFormat(reward, 2)}`;

      this.rewardText.x = this.nextClaimContainer.x - this.coinIcon.width / 2 - 5;
      this.coinIcon.x = this.rewardText.x + this.rewardText.width / 2 + this.coinIcon.width / 2 + 10;
    });
  }
}

export default DisabledClaimButton;
