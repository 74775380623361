import Popup from './Popup';
import Button from '../button/Button';
import TextButton from '../button/TextButton';
import { fontSizes, colors, fontFamilies } from '../../../../utils/styles';

class PopupGDLDeposit extends Popup {
  depositAddress = null;

  constructor(scene) {
    super(scene, 'popup-medium', { title: 'Deposit' });
    this.scene = scene;

    const leftMargin = this.popup.x - this.popup.width / 2;
    const paddedX = leftMargin + this.popup.width * 0.08;
    const imageX = paddedX + this.popup.width * 0.07;
    const sectionTextX = imageX + this.popup.width * 0.08;
    const paddedY = this.popup.y - this.popup.height / 2;

    const sectionNameStyle = { fontSize: fontSizes.large, color: colors.brown, fontFamily: fontFamilies.extraBold };
    const sectionTitleStyle = {
      fontFamily: fontFamilies.bold,
      fontSize: fontSizes.medium,
      color: colors.black,
      align: 'left',
    };
    const sectionDescriptionStyle = { ...sectionNameStyle, fontFamily: fontFamilies.bold, fontSize: fontSizes.small };

    const title1 = scene.add.text(paddedX, paddedY + 150, 'DEPOSIT VIA EXCHANGE', sectionNameStyle).setOrigin(0, 0);
    const step1Text = scene.add
      .text(paddedX, title1.y + 130, '1. Buy ETH on Binance, Coinbase, or another\nexchange.', sectionTitleStyle)
      .setOrigin(0, 0);
    const step2Text = scene.add
      .text(
        paddedX,
        step1Text.y + step1Text.height + 35,
        '2. Send/withdraw ETH to the address\nbelow and select Ethereum as the network.',
        sectionTitleStyle
      )
      .setOrigin(0, 0);

    const underline = scene.add.rectangle(this.popup.x + 15, step2Text.y + 110, 220, 4, 0x29000b);

    const container = scene.add.image(this.popup.x, step2Text.y + step2Text.height + 140, 'text-container');
    const baseIcon = scene.add.image(imageX, container.y, 'icon-eth');
    const baseTitle = scene.add.text(sectionTextX, container.y, 'Deposit address', sectionTitleStyle).setOrigin(0, 1.1);
    this.depositAddressText = scene.add
      .text(sectionTextX, container.y + 30, '', sectionDescriptionStyle)
      .setOrigin(0, 0.5);
    const copyButton = new Button(
      scene,
      leftMargin + this.popup.width * 0.83,
      container.y,
      'button-copy',
      'button-copy-pressed',
      () => {
        navigator.clipboard.writeText(this.depositAddress);
        scene.popupDepositConfirm?.open();
      },
      { sound: 'button-2' }
    );

    const title2 = scene.add.text(paddedX, container.y + 250, 'DEPOSIT VIA CARD', sectionNameStyle).setOrigin(0, 0);
    const iconGap = 50;
    const iconY = title2.y + 150;
    const icon1 = scene.add.image(paddedX, iconY, 'icon-visa').setOrigin(0, 0.5);
    const icon2 = scene.add.image(icon1.x + icon1.width + iconGap, iconY, 'icon-mastercard').setOrigin(0, 0.5);
    const icon3 = scene.add.image(icon2.x + icon2.width + iconGap, iconY, 'icon-paypal').setOrigin(0, 0.5);
    const icon4 = scene.add.image(icon3.x + icon3.width + iconGap, iconY, 'icon-google-pay').setOrigin(0, 0.5);
    const icon5 = scene.add.image(icon4.x + icon4.width + iconGap, iconY, 'icon-apple-pay').setOrigin(0, 0.5);

    const buyButton = new TextButton(
      scene,
      this.popup.x,
      iconY + 200,
      'button-blue-long-thick',
      'button-blue-long-thick-pressed',
      () => {
        scene.events.emit('s-buy-crypto-with-card');
      },
      'Buy ETH with card',
      { fontSize: '64px', sound: 'button-1', disabledImage: 'button-blue-long-thick' }
    );
    buyButton.setDisabledState(true);

    this.add([
      title1,
      step1Text,
      step2Text,
      underline,
      container,
      baseIcon,
      baseTitle,
      this.depositAddressText,
      copyButton,
      title2,
      icon1,
      icon2,
      icon3,
      icon4,
      icon5,
      buyButton,
    ]);

    scene.events.on('s-set-deposit-address', ({ depositAddress }) => {
      this.depositAddress = depositAddress;
      this.depositAddressText.text = `${depositAddress.slice(0, 5)}...${depositAddress.slice(-9)}`;
    });
  }

  onOpen() {
    if (!this.depositAddress) {
      this.scene.events.emit('s-get-deposit-address');
    }
  }

  cleanup() {
    this.scene.popupDeposit?.open();
  }
}

export default PopupGDLDeposit;
