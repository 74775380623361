import Phaser from 'phaser';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import PopupProcessing from './PopupProcessing';
import PopupConfirm from './PopupConfirm';
import PopupConfirmAugment from './PopupConfirmAugment';
import configs from '../../configs/configs';
import { customFormat, formatter, formatTimeDigit } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';

const { width, height } = configs;

const rerollBgHeight = 90;

class PopupAugments extends Popup {
  balance = 0;
  isFirstRoll = false;
  endTimeUnix = null;
  augmentItems = [];
  selected = null;
  currentAugmentUseCount = 0;
  rerollCountsLeft = 0;
  items = [];

  constructor(scene) {
    super(scene, 'popup-medium', { title: 'Augments', ribbon: 'ribbon-extra-no-shadow' });

    this.popup.setVisible(false);
    let hitArea = scene.add.graphics();
    hitArea.fillStyle(0xffffff, 0); // 0 opacity, fully invisible
    hitArea.fillRect(0, 0, 0, 0);
    hitArea
      .setInteractive(
        new Phaser.Geom.Rectangle(
          width / 2 - this.popup.width / 2,
          height / 2 - this.popup.height / 2 + 100,
          this.popup.width,
          this.popup.height
        ),
        Phaser.Geom.Rectangle.Contains
      )
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => console.log('yo'));
    this.add(hitArea);

    this.background.setFillStyle(0x260343, 0.8);

    this.popupRollProcessing = new PopupProcessing(scene, {
      sound: 'spin-result-sound',
      completedEvent: 's-roll-augments-completed',
      failedIcon: 'icon-select-augment',
      description: ``,
    });
    this.popupSelectProcessing = new PopupProcessing(scene, {
      sound: 'spin-result-sound',
      completedEvent: 's-select-augment-completed',
      failedIcon: 'icon-select-augment',
      description: ``,
    });
    this.popupConfirmReroll = new PopupConfirm(scene, this, {
      title: 'New Augments',
      action: 'roll',
      icon2: 'icon-coin-small',
      onConfirm: () => {
        this.popupRollProcessing.initLoading(`Rolling augments...\nPlease, wait`);
        scene.events.emit('s-roll-augments', { isReroll: !this.isFirstRoll });
      },
    });
    this.popupConfirm = new PopupConfirmAugment(scene, this, {
      onConfirm: () => {
        this.popupSelectProcessing.initLoading(`Selecting augment.\nPlease, wait`);
      },
    });
    scene.add.existing(this.popupRollProcessing);
    scene.add.existing(this.popupSelectProcessing);
    scene.add.existing(this.popupConfirmReroll);
    scene.add.existing(this.popupConfirm);
    this.popupConfirmReroll.updateTextLeft(`3 augments`);

    this.timerContainer = scene.add.image(this.popup.x, this.popupTitle.y + 140, 'timer-container');
    this.countdownText = scene.add
      .text(width / 2 - 110, this.timerContainer.y, '--h --m --s', {
        fontSize: fontSizes.medium,
        fontFamily: fontFamilies.bold,
        color: colors.black,
      })
      .setOrigin(0, 0.5);
    this.add(this.timerContainer);
    this.add(this.countdownText);

    this.glow = scene.add.image(this.popup.x, this.popup.y, 'glow').setVisible(false);
    this.selectedFrame = scene.add.image(this.popup.x, this.popup.y, 'gold-frame').setVisible(false);
    this.add(this.glow);
    this.add(this.selectedFrame);

    this.description = scene.add
      .text(width / 2, height / 2 + 450, '', {
        fontSize: fontSizes.large,
        fontFamily: fontFamilies.bold,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.description);

    this.buttonReroll = new TextButton(
      scene,
      width / 2 - this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        this.popupConfirmReroll.open();
      },
      'Reroll',
      { sound: 'button-1', fontSize: '72px', disabledImage: 'button-disabled' }
    );
    this.coinIcon = scene.add
      .image(width / 2 - this.popup.width * 0.23, height / 2 + this.popup.height / 2 - 20, 'icon-coin-small')
      .setVisible(false);
    this.buttonConfirm = new TextButton(
      scene,
      width / 2 + this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        this.close();
        this.popupConfirm.open();
      },
      'Confirm',
      { sound: 'button-1', fontSize: '72px', disabledImage: 'button-disabled' }
    );
    this.buttonClose = new TextButton(
      scene,
      width / 2 + this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      'Close',
      { sound: 'button-1', fontSize: '72px', disabledImage: 'button-disabled' }
    ).setVisible(false);
    this.buttonReroll.setDisabledState(true);
    this.buttonConfirm.setDisabledState(true);
    this.add(this.buttonReroll);
    this.add(this.coinIcon);
    this.add(this.buttonConfirm);
    this.add(this.buttonClose);

    this.rerollContainer = scene.add.container();
    this.rerollCountText = scene.add
      .text(this.buttonReroll.x, this.buttonReroll.y + 180, '0 times available', {
        fontSize: fontSizes.medium,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5);
    const rerollBgWidth = this.rerollCountText.width + 80;

    this.rerollBackground = scene.add
      .graphics()
      .fillStyle(0x180b8a)
      .fillRoundedRect(
        this.rerollCountText.x - rerollBgWidth / 2,
        this.rerollCountText.y - rerollBgHeight / 2,
        rerollBgWidth,
        rerollBgHeight,
        60
      );

    this.add(this.rerollContainer);
    this.rerollContainer.add(this.rerollBackground);
    this.rerollContainer.add(this.rerollCountText);

    scene.events.on('s-set-balances', ({ tokenBalance }) => {
      if (tokenBalance === this.balance) return;

      this.balance = tokenBalance;
      this.updateRollStatus();
    });

    scene.events.on('s-set-user-augments', (data) => {
      const { augmentItems, selected, rerollCountsLeft, currentAugmentUseCount } = data;
      this.augmentItems = augmentItems;
      this.isFirstRoll = !selected && !this.augmentItems?.length;
      this.rerollCountsLeft = rerollCountsLeft;
      this.selected = selected;
      this.currentAugmentUseCount = currentAugmentUseCount;
      this.renderAugments();
      this.updateRollStatus();
    });

    scene.events.on('s-set-augment-configs', (configs) => {
      const { augmentPrice, nextAugmentAt, rerollPrice } = configs;
      this.augmentPrice = augmentPrice;
      this.rerollPrice = rerollPrice;
      this.endTimeUnix = nextAugmentAt.toDate().getTime();
      this.countdown();
      this.updateRollStatus();
    });

    scene.events.emit('s-get-augment-configs');
    scene.events.emit('s-get-balances');
    setTimeout(() => {
      scene.events.emit('s-get-user-augments');
    }, 1000);
  }

  onOpen() {
    if (this.endTimeUnix && !this.selected) this.countdown();
    this.scene.events.emit('s-get-augment-configs');
    this.scene.events.emit('s-get-user-augments');
  }

  cleanup() {
    this.description.text = '';
    this.glow.setVisible(false);
    this.selectedFrame.setVisible(false);
    this.buttonConfirm.setDisabledState(true);

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  renderAugments() {
    this.clearItems();

    if (this.isFirstRoll) {
      const defaultRarites = ['epic', 'legendary', 'rare'];
      for (let i = 0; i < 3; i++) {
        const x = width / 2 + (i - 1) * 500;
        const y = height / 2 + (i === 1 ? 0 : 50);
        const rotation = (i - 1) * (Math.PI / 24);
        const rarity = defaultRarites[i];
        const item = new AugmentItem(this.scene, x, y, { rarity }, { isUnknown: true, scale: 0.7 });
        item.setRotation(rotation);
        this.add(item);
        this.items.push(item);
      }

      this.description.text = 'What mysterious perks will\nit give your gang?';

      return;
    }

    if (!this.selected) {
      this.setTitle('Select an Augment');
      for (let i = 0; i < this.augmentItems.length; i++) {
        const augment = this.augmentItems[i];
        const x = width / 2 + (i - 1) * 500;
        const y = height / 2 + (i === 1 ? 0 : 50);
        const rotation = (i - 1) * (Math.PI / 24);
        const item = new AugmentItem(this.scene, x, y, augment, {
          scale: 0.7,
          onSelect: () => {
            this.popupConfirm.setAugment(augment);
            this.description.text = augment.description.replace('{value}', formatter.format(augment.value));

            this.buttonConfirm.setDisabledState(false);
            this.glow.setVisible(true);
            this.selectedFrame.setVisible(true);

            this.glow.setX(x);
            this.selectedFrame.setX(x);
            this.selectedFrame.setY(y);
            this.selectedFrame.setRotation(rotation);
          },
        });
        item.setRotation(rotation);
        this.add(item);
        this.items.push(item);
      }
    } else {
      this.setTitle('Current Augment');
      const augment = this.selected;
      const item = new AugmentItem(this.scene, width / 2, height / 2, this.selected);
      this.add(item);
      this.items.push(item);

      this.description.text = this.currentAugmentUseCount
        ? augment.description.replace('{value}', formatter.format(augment.value))
        : augment.augmentScope === 'war'
        ? 'Used in last gang war'
        : 'Used';

      this.buttonClose.setVisible(true);
      this.buttonConfirm.setVisible(false);
    }
  }

  clearItems() {
    this.items.map((item) => {
      this.remove(item);
      item.destroy();
    });

    this.items = [];

    this.description.text = '';
  }

  updateRollStatus() {
    if (this.isFirstRoll) {
      this.popupConfirmReroll.updateTextRight(formatter.format(this.augmentPrice));
      this.buttonReroll.updateText(`Roll        ${customFormat(this.augmentPrice, 1)}`);
      this.buttonReroll.setX(width / 2);
      // this.rerollBackground.setX(width / 2);
      // this.rerollCountText.setX(width / 2);
      this.rerollContainer.setVisible(false);
      this.buttonConfirm.setVisible(false);
      this.buttonClose.setVisible(false);
      this.coinIcon.x = this.buttonReroll.x + 60 - customFormat(this.augmentPrice, 1).length * 20;
      this.coinIcon.setVisible(true);
      this.buttonReroll.setDisabledState(this.augmentPrice > this.balance);

      return;
    }

    this.popupConfirmReroll.updateTextRight(formatter.format(this.rerollPrice));

    if (this.rerollCountsLeft) {
      this.buttonReroll.updateText(`Reroll        ${customFormat(this.rerollPrice, 1)}`);
      this.coinIcon.x = this.buttonReroll.x + 100 - customFormat(this.rerollPrice, 1).length * 20;
      this.rerollCountText.text = `${this.rerollCountsLeft} time${this.rerollCountsLeft === 1 ? '' : 's'} available`;
    }

    this.buttonReroll.setDisabledState(!this.rerollCountsLeft || this.selected || this.rerollPrice > this.balance);
    if (this.selected) this.buttonReroll.updateText('Reroll');
    this.coinIcon.setVisible(this.visible && !this.selected && this.rerollCountsLeft);
    this.rerollContainer.setVisible(Boolean(this.rerollCountsLeft) && !this.selected);
    this.buttonReroll.setX(width / 2 - this.popup.width * 0.23);
    // this.rerollBackground.setX(width / 2 - this.popup.width * 0.23);
    // this.rerollCountText.setX(width / 2 - this.popup.width * 0.23);
    this.buttonConfirm.setVisible(true);

    if (this.selected) {
      this.hideCountdown();
    } else {
      this.showCountdown();
      this.countdown();
    }
  }

  showCountdown() {
    this.timerContainer.setVisible(true);
    this.countdownText.setVisible(true);
  }

  hideCountdown() {
    this.timerContainer.setVisible(false);
    this.countdownText.setVisible(false);

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  countdown() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.endTimeUnix) {
      this.showEndTime();
      this.interval = setInterval(() => this.showEndTime(), 1000);
    }
  }

  showEndTime() {
    const now = Date.now();
    const diff = this.endTimeUnix - now;

    if (diff <= 0) {
      this.countdownText.setVisible(false);
      clearInterval(this.interval);
      this.interval = null;
      return;
    }

    const diffInSeconds = Math.max(diff / 1000, 0);
    const hours = Math.floor((diffInSeconds % 86400) / 3600);
    const mins = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.round(diffInSeconds % 60);

    this.countdownText.setVisible(true);
    this.countdownText.text = `${formatTimeDigit(hours)}h ${formatTimeDigit(mins)}m ${formatTimeDigit(seconds)}s`;
  }
}

export default PopupAugments;

export class AugmentItem extends Phaser.GameObjects.Container {
  constructor(scene, x, y, item, { scale = 1, onSelect, isUnknown } = {}) {
    super(scene, x, y);

    const { name, rarity, value, type, image } = item;

    let valuePrefix = '';
    let valueSuffix = '';
    switch (type) {
      // multipliers
      case 'attack-multiplier':
      case 'defence-multiplier':
      case 'earnings-multiplier':
        valueSuffix = 'x';
        break;
      // assets
      case 'safehouse-instant-bonus':
      case 'goon-instant-bonus':
      case 'gangster-instant-bonus':
        valuePrefix = 'x';
        break;
      case 'attack-bonus':
      case 'defence-bonus':
      case 'earnings-bonus':
      case 'xgreed-instant-bonus':
        valuePrefix = '+';
        break;
    }

    this.container = scene.add.sprite(0, 0, `augment-container-${rarity}`).setOrigin(0.5, 0.5).setScale(scale);
    this.add(this.container);

    if (isUnknown) {
      this.icon = scene.add.image(0, 0, 'unknown').setOrigin(0.5, 0.5).setScale(scale);
      this.add(this.icon);
      return;
    }

    this.icon = scene.add
      .image(0, 0 - 60, image)
      .setOrigin(0.5, 0.5)
      .setScale(scale);
    this.text = scene.add
      .text(0, 200 * scale, name, {
        fontSize: scale === 1 ? fontSizes.large : fontSizes.medium,
        color: colors.yellow,
        fontFamily: fontFamilies.extraBold,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.valueText = scene.add
      .text(100, 80 * Math.pow(scale, 2), `${valuePrefix}${formatter.format(value)}${valueSuffix}`, {
        fontSize: scale === 1 ? fontSizes.extraLarge : fontSizes.large,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.text.setStroke(colors.brown, 15);
    this.valueText.setStroke(colors.brown, 15);

    if (onSelect)
      this.container.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        onSelect?.();
      });

    // augment name too long
    if (this.text.width > this.container.displayWidth * 0.95) {
      const [secondLine, ...firstLineParts] = name.split(' ').reverse();
      this.text.text = `${firstLineParts.join(' ')}\n${secondLine}`;
    }

    this.add(this.icon);
    this.add(this.valueText);
    this.add(this.text);
  }
}
