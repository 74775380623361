import { useState } from 'react';

const usePriceTracking = () => {
  const [listeningWorker, setListeningWorker] = useState(false);
  const [listeningBuilding, setListeningBuilding] = useState(false);
  const [listeningMachine, setListeningMachine] = useState(false);
  const [listeningThug, setListeningThug] = useState(false);

  const enableWorkerSalesTracking = () => setListeningWorker(true);
  const disableWorkerSalesTracking = () => setListeningWorker(false);
  const enableBuildingSalesTracking = () => setListeningBuilding(true);
  const disableBuildingSalesTracking = () => setListeningBuilding(false);
  const enableMachineSalesTracking = () => setListeningMachine(true);
  const disableMachineSalesTracking = () => setListeningMachine(false);
  const enableThugSalesTracking = () => setListeningThug(true);
  const disableThugSalesTracking = () => setListeningThug(false);

  return {
    listeningBuilding,
    listeningMachine,
    listeningWorker,
    listeningThug,
    enableWorkerSalesTracking,
    disableWorkerSalesTracking,
    enableBuildingSalesTracking,
    disableBuildingSalesTracking,
    enableMachineSalesTracking,
    disableMachineSalesTracking,
    enableThugSalesTracking,
    disableThugSalesTracking,
  };
};

export default usePriceTracking;
