import Popup from './Popup';
import TextButton from '../button/TextButton';
import TextInput from '../inputs/TextInput';

import { numberCharacterRegex, numberInputRegex } from '../../../../utils/strings';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';

const sectionNameStyle = { fontSize: fontSizes.large, color: colors.brown, fontFamily: fontFamilies.bold };
const sectionTitleStyle = { ...sectionNameStyle, fontSize: fontSizes.medium, color: colors.black };

const buttonWidth = 506;
const btnGap = 50;

class PopupPrivyDeposit extends Popup {
  value = 0;

  constructor(scene) {
    super(scene, 'popup-mini', { title: 'Privy Deposit' });
    this.scene = scene;

    const backBtn = new TextButton(
      scene,
      this.popup.x - buttonWidth / 2 - btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      `Back`,
      { sound: 'close', fontSize: '82px' }
    );

    this.confirmBtn = new TextButton(
      scene,
      this.popup.x + buttonWidth / 2 + btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        scene.events.emit('s-fund-wallet', { value: this.value });
      },
      'Deposit',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.confirmBtn.setDisabledState(true);

    const text = scene.add
      .text(
        this.popup.x,
        this.popup.y - this.popup.height / 2 + 170,
        'Deposit from any external wallet\nto your Privy wallet on Blast',
        sectionNameStyle
      )
      .setOrigin(0.5, 0);

    this.balanceText = scene.add
      .text(this.popup.x, text.y + text.height + 50, 'My balance: --- ETH', sectionNameStyle)
      .setOrigin(0.5, 0);

    const label = scene.add
      .text(
        this.popup.x - this.popup.width / 2 + 100,
        this.balanceText.y + this.balanceText.height + 50,
        'Balance to deposit',
        sectionTitleStyle
      )
      .setOrigin(0, 0);

    this.input = new TextInput(scene, this.popup.x, label.y + label.height + 100, {
      icon: 'icon-eth',
      placeholder: '0.00',
      unit: 'ETH',
      valueRegex: numberInputRegex,
      characterRegex: numberCharacterRegex,
      maxDisplayedCharacters: 13,
      onChange: (value) => {
        this.value = value;
        this.validate();
      },
    });

    this.add([backBtn, this.confirmBtn, text, this.balanceText, label, this.input]);

    scene.events.on('s-set-eth-balance', ({ ETHBalance }) => {
      this.balanceText.text = `My balance: ${formatter.format(ETHBalance)} ETH`;
    });
  }

  validate() {
    if (!numberCharacterRegex.test(this.value) || !numberInputRegex.test(this.value)) {
      this.confirmBtn.setDisabledState(true);
      return;
    }

    this.confirmBtn.setDisabledState(false);
  }

  onOpen() {
    this.scene.events.emit('s-get-eth-balance');
  }

  cleanup() {
    this.scene.popupDeposit?.open();
    this.value = 0;
    this.input.updateValue('');
    this.confirmBtn.setDisabledState(true);
  }
}

export default PopupPrivyDeposit;
