import Phaser from 'phaser';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import { formatter } from '../../../../utils/numbers';
import { fontFamilies } from '../../../../utils/styles';

const buttonWidth = 506;
const btnGap = 50;

const normalTextStyle = {
  fontSize: '60px',
  color: '#29000B',
  fontFamily: fontFamilies.semiBold,
};

const boldTextStyle = {
  ...normalTextStyle,
  fontFamily: fontFamilies.extraBold,
};

class PopupClaimConfirm extends Popup {
  checked = false;
  onClaim = () => {};

  constructor(scene) {
    super(scene, 'popup-tiny', { title: 'Claim' });
    this.scene = scene;

    this.backBtn = new TextButton(
      scene,
      this.popup.x - buttonWidth / 2 - btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      `Don't Claim`,
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    this.confirmBtn = new TextButton(
      scene,
      this.popup.x + buttonWidth / 2 + btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        this.close();
        this.onClaim?.({ checked: this.checked });
      },
      'Claim',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.add(this.confirmBtn);

    const text1Y = this.popup.y - this.popup.height / 2 + 170;
    const text2Y = text1Y + 100;
    const text3Y = text2Y + 75;
    const text4Y = text3Y + 140;

    this.text1 = scene.add.text(this.popup.x, text1Y, 'You are about to claim', normalTextStyle).setOrigin(0, 0.5);
    this.rewardText = scene.add.text(this.popup.x, text1Y, '', boldTextStyle).setOrigin(0, 0.5);

    this.text2 = scene.add.text(this.popup.x, text2Y, 'Are you sure? There is a', normalTextStyle).setOrigin(0, 0.5);
    this.cooldownText = scene.add.text(this.popup.x, text2Y, '', boldTextStyle).setOrigin(0, 0.5);
    this.text3 = scene.add
      .text(this.popup.x, text3Y, 'cool down before you can claim again', normalTextStyle)
      .setOrigin(0.5, 0.5);

    this.icon = scene.add.sprite(this.popup.x - 320, text4Y, 'icon-checkbox-false').setOrigin(0, 0.5);
    this.icon.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      this.checked = !this.checked;
      if (this.checked) {
        this.icon.setTexture('icon-checkbox-true');
      } else {
        this.icon.setTexture('icon-checkbox-false');
      }
    });
    this.noteText = scene.add
      .text(this.icon.x + this.icon.width + 40, text4Y, `Don't show again`, boldTextStyle)
      .setOrigin(0, 0.5);

    this.add([this.text1, this.rewardText, this.text2, this.cooldownText, this.text3, this.icon, this.noteText]);

    scene.events.on('s-set-claim-cool-down', ({ claimGapInSeconds }) => {
      const hour = Math.floor(claimGapInSeconds / 3600);
      this.cooldownText.text = `${hour} hour`;

      const totalWidth = this.text2.width + 20 + this.cooldownText.width;
      this.text2.x = this.popup.x - totalWidth / 2;
      this.cooldownText.x = this.text2.x + this.text2.width + 20;
    });
  }

  show({ reward, onClaim }) {
    this.onClaim = onClaim;

    this.rewardText.text = `${formatter.format(reward)}`;
    const totalWidth = this.text1.width + this.rewardText.width + 20;
    this.text1.x = this.popup.x - totalWidth / 2;
    this.rewardText.x = this.text1.x + this.text1.width + 20;

    this.open();
  }
}

export default PopupClaimConfirm;
