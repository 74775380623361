import Phaser from 'phaser';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';
import Step10 from './Step10';
import Step11 from './Step11';
import Step12 from './Step12';
import configs from '../../configs/configs';

const { width, height } = configs;

class Tutorial extends Phaser.GameObjects.Container {
  // use this to track tutorial progress if needed
  // only need in 1st step for now
  currentStep = 1;

  constructor(scene, overlayContainer) {
    super(scene, 0, 0);

    this.background = scene.add.rectangle(0, 0, width, height, 0x260343, 0.8).setOrigin(0, 0).setDepth(5);
    this.add(this.background);

    this.step1 = new Step1(scene);
    this.add(this.step1);

    this.step2 = new Step2(scene, () => {
      this.step2.destroy();
      this.step3.setVisible(true);
    });
    this.add(this.step2);

    this.step3 = new Step3(scene, () => {
      this.step3.setVisible(false);
      this.step4.start();
    });
    this.add(this.step3);

    this.step4 = new Step4(scene, overlayContainer, () => {
      this.step4.setVisible(false);
      this.step5.setVisible(true);
    });
    this.add(this.step4);

    this.step5 = new Step5(scene, () => {
      this.step5.setVisible(false);
      this.step6.setVisible(true);
    });
    this.add(this.step5);

    this.step6 = new Step6(scene, () => {
      this.step6.setVisible(false);
      this.step7.setVisible(true);
    });
    this.add(this.step6);

    this.step7 = new Step7(scene, () => {
      this.step7.setVisible(false);
      this.step8.start();
    });
    this.add(this.step7);

    this.step8 = new Step8(scene, () => {
      this.step8.setVisible(false);
      this.step9.setVisible(true);
    });
    this.add(this.step8);

    this.step9 = new Step9(scene, () => {
      this.step9.setVisible(false);
      this.step10.setVisible(true);
    });
    this.add(this.step9);

    this.step10 = new Step10(scene, () => {
      this.step10.setVisible(false);
      this.step11.setVisible(true);
    });
    this.add(this.step10);

    this.step11 = new Step11(scene, () => {
      this.step11.setVisible(false);
      this.step12.start();
    });
    this.add(this.step11);

    this.step12 = new Step12(scene, overlayContainer);
    this.add(this.step12);

    this.step1.setVisible(true);
    this.background.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, this.moveToStep2);
  }

  moveToStep2() {
    if (this.scene.tutorial.currentStep === 1) {
      this.scene.tutorial.step1.destroy();
      this.scene.tutorial.background.setInteractive().removeAllListeners();
      this.scene.tutorial.step2.start();
      this.scene.tutorial.currentStep = 2;
    }
  }
}

export default Tutorial;
