import Phaser from 'phaser';

import TutorialCharacter from './TutorialCharacter';
import InfoButtons from '../action-buttons/InfoButtons';
import configs from '../../configs/configs';

const { width, height } = configs;

const px = 40;
const buttonWidth = 230;
const verticalGap = 186 + 50;
const auctionBtnY = 550 + verticalGap;

class Step10 extends Phaser.GameObjects.Container {
  clicked = false;

  constructor(scene, onNext) {
    super(scene, 0, 0);

    this.setVisible(false);

    this.character = new TutorialCharacter(scene, width / 2, height / 2, 'tutorial-10', () => {});
    this.add(this.character);

    this.infoButton = new InfoButtons(scene, 550, {
      noBackground: true,
      hideSettings: true,
      hideReward: true,
      hideLeaderboard: true,
      hideAugments: true,
      onClickAuction: () => {
        onNext();
      },
    });
    this.add(this.infoButton);

    this.arrow = scene.add.image(buttonWidth + px, auctionBtnY, 'tutorial-arrow-left').setOrigin(0, 0.5);
    this.add(this.arrow);
  }
}

export default Step10;
