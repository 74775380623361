export const estimateNumberOfBuildingCanBuy = (
  balance,
  totalSold,
  days,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice,
  maxPerBatch
) => {
  let quantity = 0;
  let total = calculateNextBuildingBuyPriceBatch(
    totalSold,
    days,
    targetDailyPurchase,
    pricePower,
    targetPrice,
    startPrice,
    quantity
  ).total;
  let nextTotal = calculateNextBuildingBuyPriceBatch(
    totalSold,
    days,
    targetDailyPurchase,
    pricePower,
    targetPrice,
    startPrice,
    quantity + 1
  ).total;
  while (quantity < maxPerBatch && nextTotal <= balance) {
    quantity++;
    total = calculateNextBuildingBuyPriceBatch(
      totalSold,
      days,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice,
      quantity
    ).total;
    nextTotal = calculateNextBuildingBuyPriceBatch(
      totalSold,
      days,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice,
      quantity + 1
    ).total;
  }

  return quantity;
};

export const estimateNumberOfThugCanBuy = (
  balance,
  totalSold,
  days,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice,
  maxPerBatch
) => {
  let quantity = 0;
  let total = calculateNextThugBuyPriceBatch(
    totalSold,
    days,
    targetDailyPurchase,
    pricePower,
    targetPrice,
    startPrice,
    quantity
  ).total;
  let nextTotal = calculateNextThugBuyPriceBatch(
    totalSold,
    days,
    targetDailyPurchase,
    pricePower,
    targetPrice,
    startPrice,
    quantity + 1
  ).total;
  while (quantity < maxPerBatch && nextTotal <= balance) {
    quantity++;
    total = calculateNextThugBuyPriceBatch(
      totalSold,
      days,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice,
      quantity
    ).total;
    nextTotal = calculateNextThugBuyPriceBatch(
      totalSold,
      days,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice,
      quantity + 1
    ).total;
  }

  return quantity;
};

export const estimateNumberOfWorkerCanBuy = (
  balance,
  totalSold,
  days,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice,
  maxPerBatch
) => {
  let quantity = 0;
  let total = calculateNextWorkerBuyPriceBatch(
    totalSold,
    days,
    targetDailyPurchase,
    pricePower,
    targetPrice,
    startPrice,
    quantity
  ).total;
  let nextTotal = calculateNextWorkerBuyPriceBatch(
    totalSold,
    days,
    targetDailyPurchase,
    pricePower,
    targetPrice,
    startPrice,
    quantity + 1
  ).total;
  while (quantity < maxPerBatch && nextTotal <= balance) {
    quantity++;
    total = calculateNextWorkerBuyPriceBatch(
      totalSold,
      days,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice,
      quantity
    ).total;
    nextTotal = calculateNextWorkerBuyPriceBatch(
      totalSold,
      days,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice,
      quantity + 1
    ).total;
  }

  return quantity;
};

export const estimateNumberOfMachineCanBuy = (
  balance,
  totalSold,
  days,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice,
  maxPerBatch
) => {
  let quantity = 0;
  let total = calculateNextMachineBuyPriceBatch(
    totalSold,
    days,
    targetDailyPurchase,
    pricePower,
    targetPrice,
    startPrice,
    quantity
  ).total;
  let nextTotal = calculateNextMachineBuyPriceBatch(
    totalSold,
    days,
    targetDailyPurchase,
    pricePower,
    targetPrice,
    startPrice,
    quantity + 1
  ).total;
  while (quantity < maxPerBatch && nextTotal <= balance) {
    quantity++;
    total = calculateNextMachineBuyPriceBatch(
      totalSold,
      days,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice,
      quantity
    ).total;
    nextTotal = calculateNextMachineBuyPriceBatch(
      totalSold,
      days,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice,
      quantity + 1
    ).total;
  }

  return quantity;
};

export const calculateNextWorkerBuyPrice = (
  avgDailyPurchase,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice
) => {
  return Math.pow(avgDailyPurchase / targetDailyPurchase, pricePower) * targetPrice + startPrice;
};

export const calculateNextWorkerBuyPriceBatch = (
  totalSold,
  days,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice,
  quantity
) => {
  let count = 0;
  let sold = totalSold;
  const prices = [];
  while (count < quantity) {
    const avgDailyPurchase = sold / days;
    const price = calculateNextWorkerBuyPrice(
      avgDailyPurchase,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice
    );
    prices.push(price);
    sold++;
    count++;
  }

  return {
    total: prices.reduce((total, item) => total + item, 0),
    prices,
  };
};

export const calculateNextBuildingBuyPrice = (
  avgDailyPurchase,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice
) => {
  return Math.pow(avgDailyPurchase / targetDailyPurchase, pricePower) * targetPrice + startPrice;
};

export const calculateNextBuildingBuyPriceBatch = (
  totalSold,
  days,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice,
  quantity
) => {
  let count = 0;
  let sold = totalSold;
  const prices = [];
  while (count < quantity) {
    const avgDailyPurchase = sold / days;
    const price = calculateNextBuildingBuyPrice(
      avgDailyPurchase,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice
    );
    prices.push(price);
    sold++;
    count++;
  }

  return {
    total: prices.reduce((total, item) => total + item, 0),
    prices,
  };
};

export const calculateNextThugBuyPrice = (
  avgDailyPurchase,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice
) => {
  return Math.pow(avgDailyPurchase / targetDailyPurchase, pricePower) * targetPrice + startPrice;
};

export const calculateNextThugBuyPriceBatch = (
  totalSold,
  days,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice,
  quantity
) => {
  let count = 0;
  let sold = totalSold;
  const prices = [];
  while (count < quantity) {
    const avgDailyPurchase = sold / days;
    const price = calculateNextBuildingBuyPrice(
      avgDailyPurchase,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice
    );
    prices.push(price);
    sold++;
    count++;
  }

  return {
    total: prices.reduce((total, item) => total + item, 0),
    prices,
  };
};

export const calculateNextMachineBuyPrice = (
  avgDailyPurchase,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice
) => {
  return Math.pow(avgDailyPurchase / targetDailyPurchase, pricePower) * targetPrice + startPrice;
};

export const calculateNextMachineBuyPriceBatch = (
  totalSold,
  days,
  targetDailyPurchase,
  pricePower,
  targetPrice,
  startPrice,
  quantity
) => {
  let count = 0;
  let sold = totalSold;
  const prices = [];
  while (count < quantity) {
    const avgDailyPurchase = sold / days;
    const price = calculateNextMachineBuyPrice(
      avgDailyPurchase,
      targetDailyPurchase,
      pricePower,
      targetPrice,
      startPrice
    );
    prices.push(price);
    sold++;
    count++;
  }

  return {
    total: prices.reduce((total, item) => total + item, 0),
    prices,
  };
};

export const calculateHouseLevel = (houseLevels, networth) => {
  const levelItem = houseLevels.find((item) => {
    let valid = networth >= item.networthStart;
    if (!valid) return false;

    if (item.networthEnd) {
      valid = networth <= item.networthEnd;
    }

    return valid;
  });

  return levelItem?.level;
};

export const calculateSpinPrice = (networth, costReputationMultiplier, basePrice) => {
  return networth * costReputationMultiplier + basePrice;
};

export const getTokenFromXToken = (xToken) => {
  return xToken;
};

export const calculateNextPistolPrice = (purchased, basePrice, priceDivision, pricePower) => {
  return basePrice * Math.pow(1 + purchased / priceDivision, pricePower);
};

export const calculateNextPistolPriceBatch = (purchased, basePrice, priceDivision, pricePower, quantity) => {
  let count = 0;
  let sold = purchased;
  const prices = [];
  while (count < quantity) {
    const price = calculateNextPistolPrice(sold, basePrice, priceDivision, pricePower);
    prices.push(price);
    sold++;
    count++;
  }

  return {
    total: prices.reduce((total, item) => total + item, 0),
    prices,
  };
};

export const calculateNextShieldPrice = (purchased, basePrice, priceDivision, pricePower) => {
  return basePrice * Math.pow(1 + purchased / priceDivision, pricePower);
};

export const calculateNextShieldPriceBatch = (purchased, basePrice, priceDivision, pricePower, quantity) => {
  let count = 0;
  let sold = purchased;
  const prices = [];
  while (count < quantity) {
    const price = calculateNextPistolPrice(sold, basePrice, priceDivision, pricePower);
    prices.push(price);
    sold++;
    count++;
  }

  return {
    total: prices.reduce((total, item) => total + item, 0),
    prices,
  };
};
