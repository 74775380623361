import { useConnect, useAccount, useDisconnect, useSignMessage } from 'wagmi';
import * as Sentry from '@sentry/react';

import { getWalletNonce, linkWallet } from '../services/user.service';
import { useEffect } from 'react';

const useWagmi = () => {
  const { address, isConnected, isConnecting } = useAccount();
  const { disconnect } = useDisconnect();
  const { connectors: allConnectors, connect } = useConnect();
  const { signMessageAsync } = useSignMessage();

  const connectors = allConnectors.filter((item) => item.type !== 'injected');

  const signMessage = async ({ account }) => {
    const res = await getWalletNonce({ address: account });
    const message = `Welcome to Gangster Arena!\n\nSign this message to link your wallet\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nNonce: ${res.data}`;
    const signature = await signMessageAsync({ message });
    await linkWallet({ message, signature });
  };

  return {
    connectors,
    address,
    isConnected,
    isConnecting,
    connect,
    disconnect,
    signMessage,
  };
};

export default useWagmi;
