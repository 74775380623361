import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { customFormat } from '../../../../utils/numbers';

const { width, height } = configs;
const largeBlackBold = {
  fontSize: fontSizes.large,
  color: colors.black,
  fontFamily: fontFamilies.bold,
};

const iconX = width / 2 - 190;
const iconOffset = 70;

class PopupConfirmWarUpgrades extends Popup {
  gas = 0;
  pistolQuantity = 0;
  shieldQuantity = 0;

  constructor(scene, parentModal, { icon2, onConfirm, onOpen, onClose }) {
    super(scene, 'popup-tiny', { title: 'Buy Upgrades' });
    this.onOpen = onOpen;
    this.onClose = onClose;

    const startingY = this.popup.y - this.popup.height / 2;
    const areYouSureY = startingY + 150;
    const mainTextY = areYouSureY + 230;

    const areYouSure = scene.add
      .text(width / 2, areYouSureY, `Are you sure you want to buy:`, {
        ...largeBlackBold,
        align: 'center',
      })
      .setOrigin(0.5, 0);
    this.add(areYouSure);
    const bg = scene.add.image(width / 2, mainTextY, 'container-short');
    this.add(bg);

    const forText = scene.add.text(width / 2, mainTextY, `for`, largeBlackBold).setOrigin(0, 0.5);
    this.add(forText);
    this.textLeft = scene.add.text(width / 2 - 20, mainTextY, '', largeBlackBold).setOrigin(1, 0.5);
    this.add(this.textLeft);

    this.pistol = scene.add.container();
    this.shield = scene.add.container();
    this.iconPistol = scene.add.image(iconX - iconOffset, mainTextY, 'icon-pistol').setOrigin(0.5, 0.5);
    this.iconShield = scene.add.image(iconX + iconOffset, mainTextY, 'icon-shield').setOrigin(0.5, 0.5);
    this.textPistol = scene.add
      .text(this.iconPistol.x + 10, mainTextY, '', {
        fontSize: '56px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setStroke('#6F0132', 10);
    this.textShield = scene.add
      .text(this.iconShield.x + 10, mainTextY, '', {
        fontSize: '56px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setStroke('#6F0132', 10);
    this.add(this.pistol);
    this.add(this.shield);
    this.pistol.add(this.iconPistol);
    this.pistol.add(this.textPistol);
    this.shield.add(this.iconShield);
    this.shield.add(this.textShield);

    this.textRight = scene.add
      .text(width / 2 + 100, mainTextY, '', { ...largeBlackBold, fontFamily: fontFamilies.extraBold })
      .setOrigin(0, 0.5);
    this.iconRight = scene.add.image(width / 2 + this.popup.width * 0.28, mainTextY, icon2).setOrigin(0.5, 0.5);
    this.add(this.textRight);
    this.add(this.iconRight);

    const buttonNo = new TextButton(
      scene,
      width / 2 - this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        parentModal.open();
      },
      'No',
      { fontSize: '82px', sound: 'close' }
    );
    this.buttonYes = new TextButton(
      scene,
      width / 2 + this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        onConfirm();
        this.close();
      },
      'Yes',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.add(buttonNo);
    this.add(this.buttonYes);
  }

  updateQuantity({ pistolQuantity, shieldQuantity }) {
    this.pistolQuantity = pistolQuantity;
    this.shieldQuantity = shieldQuantity;

    this.textPistol.text = customFormat(pistolQuantity, 2);
    this.textShield.text = customFormat(shieldQuantity, 2);

    this.pistol.setVisible(Boolean(pistolQuantity));
    this.shield.setVisible(Boolean(shieldQuantity));

    if (pistolQuantity * shieldQuantity === 0) {
      const visibleItem = pistolQuantity ? this.iconPistol : this.iconShield;
      visibleItem.setX(iconX);
    } else {
      this.iconPistol.setX(iconX - iconOffset);
      this.iconShield.setX(iconX + iconOffset);
    }

    this.textPistol.setX(this.iconPistol.x + 10);
    this.textShield.setX(this.iconShield.x + 10);
  }
  updateTextRight(text) {
    this.textRight.text = text;
    this.iconRight.x = this.textRight.x + this.textRight.width + 60;
  }
  updateIconRight(texture) {
    this.iconRight.setTexture(texture);
  }
}

export default PopupConfirmWarUpgrades;
