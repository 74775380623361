import api from './api';

export const getLeaderboard = ({ page, limit }) => api.get('/api/v1/gamePlays', { params: { page, limit } });

export const getRaidPointLeaderboard = ({ page, limit }) =>
  api.get('/api/v1/gamePlays/raid-point', { params: { page, limit } });

export const getUPointLeaderboard = ({ page, limit }) =>
  api.get('/api/v1/gamePlays/u-point-leaderboard', { params: { page, limit } });

export const getNextWarSnapshotUnixTime = () => api.get('/api/v1/gamePlays/next-war-time');

export const updateLastTimeSeenGangWarResult = () => api.put('/api/v1/gamePlays/last-time-seen-war-result');

export const updateUserWarDeployment = (data) => api.put('/api/v1/gamePlays/war-deployment', data);

export const getUserWarDeployment = () => api.get('/api/v1/gamePlays/war-deployment');

export const getNextSpinIncrementUnixTime = () => api.get('/api/v1/gamePlays/next-spin-increment-time');
