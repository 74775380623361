import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import Button from '../button/Button';
import { formatter } from '../../../../utils/numbers';
import environments from '../../../../utils/environments';

const { ENVIRONMENT } = environments;
const { width, height } = configs;

const isTestnet = ENVIRONMENT !== 'production';
class PopupDeposit extends Popup {
  isSimulator;
  loading = false;
  address = '';

  constructor(scene, parentModal, { onOpen, onClose, ...configs } = {}) {
    super(scene, 'popup-medium', { title: 'Deposit', ...configs });
    this.isSimulator = scene.name === 'TutorialScene';
    this.onOpenCallback = onOpen;
    this.cleanup = onClose;

    const leftMargin = this.popup.x - this.popup.width / 2;
    const paddedX = leftMargin + this.popup.width * 0.1;
    const imageX = paddedX + this.popup.width * 0.07;
    const sectionTextX = imageX + this.popup.width * 0.08;
    const startingY = this.popup.y - this.popup.height / 2;
    const subtitleY = startingY + 150;
    const gblContainerY = subtitleY + 320;
    const fundingContainerY = gblContainerY + 340;
    const baseContainerY = fundingContainerY + 340;
    const balanceTextY = baseContainerY + 180;

    const sectionNameStyle = { fontSize: fontSizes.medium, color: colors.brown, fontFamily: fontFamilies.bold };
    const sectionTitleStyle = { ...sectionNameStyle, color: colors.black };
    const sectionDescriptionStyle = { ...sectionNameStyle, fontSize: fontSizes.small };

    // user details
    const subtitle = scene.add
      .text(width / 2, subtitleY, 'Deposit ETH or NFTs here', {
        fontSize: fontSizes.large,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0);

    const gdlName = scene.add
      .text(paddedX, gblContainerY, 'Deposit via Exchange or Card', sectionNameStyle)
      .setOrigin(0, 3.5);
    const gdlContainer = scene.add.image(width / 2, gblContainerY, 'text-container');
    const gdlIcon = scene.add.image(imageX, gblContainerY, 'icon-blast');
    const gdlTitle = scene.add.text(sectionTextX, gblContainerY, 'Blast GDL', sectionTitleStyle).setOrigin(0, 1.1);
    const gdlDescription = scene.add
      .text(sectionTextX, gblContainerY, 'Global Deposit Layer', sectionDescriptionStyle)
      .setOrigin(0, -0.1);
    const gdlDepositButton = new Button(
      scene,
      width / 2 + this.popup.width * 0.27,
      gblContainerY,
      'button-deposit',
      'button-deposit-pressed',
      () => {
        this.close();
        scene.popupGDLDeposit?.open();
      },
      { sound: 'open', disabledImage: 'button-deposit-disabled' }
    );
    if (this.isSimulator || isTestnet) {
      gdlDepositButton.setDisabledState(true);
    }

    const fundingName = scene.add.text(paddedX, fundingContainerY, 'Deposit ETH', sectionNameStyle).setOrigin(0, 3.5);
    const fundingContainer = scene.add.image(width / 2, fundingContainerY, 'text-container');
    const fundingIcon = scene.add.image(imageX, fundingContainerY, 'icon-privy');
    const fundingTitle = scene.add.text(sectionTextX, fundingContainerY, 'Privy', sectionTitleStyle).setOrigin(0, 1.1);
    const fundingDescription = scene.add
      .text(sectionTextX, fundingContainerY, 'Privy Funding Wallets', sectionDescriptionStyle)
      .setOrigin(0, -0.1);
    const fundingDepositButton = new Button(
      scene,
      width / 2 + this.popup.width * 0.27,
      fundingContainerY,
      'button-deposit',
      'button-deposit-pressed',
      () => {
        this.close();
        scene.popupPrivyDeposit?.open();
      },
      { sound: 'open', disabledImage: 'button-deposit-disabled' }
    );

    if (this.isSimulator || isTestnet) {
      fundingDepositButton.setDisabledState(true);
    }

    const baseName = scene.add
      .text(paddedX, baseContainerY, 'Deposit ETH, Coins or NFTs', sectionNameStyle)
      .setOrigin(0, 3.5);
    const baseContainer = scene.add.image(width / 2, baseContainerY, 'text-container');
    const baseIcon = scene.add.image(imageX, baseContainerY, 'icon-wallet');
    const baseTitle = scene.add
      .text(sectionTextX, baseContainerY, 'Receive on Blast', sectionTitleStyle)
      .setOrigin(0, 1.1);
    this.baseDescription = scene.add.text(sectionTextX, baseContainerY, '', sectionDescriptionStyle).setOrigin(0, -0.1);
    const copyButton = new Button(
      scene,
      leftMargin + this.popup.width * 0.83,
      baseContainerY,
      'button-copy',
      'button-copy-pressed',
      () => navigator.clipboard.writeText(this.address),
      { sound: 'button-2' }
    );

    const balanceSubtitle = scene.add
      .text(paddedX + this.popup.width * 0.02, balanceTextY, 'GangsterArena Wallet Balance: ', sectionDescriptionStyle)
      .setOrigin(0, 0.5);
    this.balanceText = scene.add
      .text(width / 2 + this.popup.width * 0.08, balanceTextY, '0 ETH', {
        ...sectionDescriptionStyle,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);

    const refreshButton = new Button(
      scene,
      width / 2 + this.popup.width * 0.3,
      balanceTextY,
      'button-refresh',
      'button-refresh-pressed',
      () => {
        if (this.loading) return;
        this.loading = true;
        scene.events.emit('s-get-eth-balance');
      },
      { sound: 'button-1' }
    );

    this.add(subtitle);
    this.add(gdlName);
    this.add(gdlContainer);
    this.add(gdlIcon);
    this.add(gdlTitle);
    this.add(gdlDescription);
    this.add(gdlDepositButton);
    this.add(fundingName);
    this.add(fundingContainer);
    this.add(fundingIcon);
    this.add(fundingTitle);
    this.add(fundingDescription);
    this.add(fundingDepositButton);
    this.add(baseName);
    this.add(baseContainer);
    this.add(baseIcon);
    this.add(baseTitle);
    this.add(this.baseDescription);
    this.add(copyButton);
    this.add(balanceSubtitle);
    this.add(this.balanceText);
    this.add(refreshButton);

    const buttonBack = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        parentModal?.open();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(buttonBack);

    scene.events.on('s-set-eth-balance', ({ address, ETHBalance }) => {
      this.balanceText.text = `${formatter.format(ETHBalance)} ETH`;
      this.updateAddress(address);
      this.loading = false;
    });
  }

  updateAddress(address) {
    this.address = address;
    this.baseDescription.text = `${address?.slice(0, 5)}...${address?.slice(-9)}`;
  }

  onOpen() {
    this.scene.events.emit('s-get-eth-balance');
    this.onOpenCallback?.();
  }
}

export default PopupDeposit;
