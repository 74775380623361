import { MoonPayBuyWidget } from '@moonpay/moonpay-react';
import * as Sentry from '@sentry/react';

import useModalStore from '../stores/modal.store';
import useUserStore from '../stores/user.store';

import { getMoonPaySignature } from '../services/user.service';

const MoonPay = () => {
  const openMoonPay = useModalStore((state) => state.openMoonPay);
  const setOpenMoonPay = useModalStore((state) => state.setOpenMoonPay);
  const profile = useUserStore((state) => state.profile);

  const handleGetSignature = async (url) => {
    try {
      const res = await getMoonPaySignature({ url });
      const { signature } = res.data;
      return signature;
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      return '';
    }
  };

  if (!openMoonPay) return null;

  return (
    <MoonPayBuyWidget
      variant="overlay"
      currencyCode="eth"
      walletAddress={profile?.depositAddress}
      onUrlSignatureRequested={handleGetSignature}
      visible
      onCloseOverlay={() => setOpenMoonPay(false)}
    />
  );
};

export default MoonPay;
