export const SCANNER_URL = {
  8453: 'basescan.org',
  84531: 'goerli.basescan.org',
  84532: 'sepolia.basescan.org',
  81457: 'blastscan.io',
  168587773: 'testnet.blastscan.io',
};

// hold to buy more
export const fibonacis = [1, 2, 3, 5, 8, 13, 21];
export const maxStepCount = 7;
export const maxStepSizeIndex = fibonacis.length - 1;
