import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import Popup from './Popup';
import Button from '../button/Button';
import TextButton from '../button/TextButton';
import { formatter } from '../../../../utils/numbers';
import { colors, fontFamilies } from '../../../../utils/styles';
import environments from '../../../../utils/environments';
import { SCANNER_URL } from '../../../../utils/constants';
import configs from '../../configs/configs';

const { width, height } = configs;

const getLinkTxn = (txnHash) => `https://${SCANNER_URL[environments.NETWORK_ID]}/tx/${txnHash}`;

const rowHeight = 119;
const paginationBtnSize = 66;
const paginationBtnGap = 15;
const smallBlackBoldCenter = {
  fontSize: '40px',
  color: colors.black,
  fontFamily: fontFamilies.bold,
  align: 'center',
};

class PopupSpinHistory extends Popup {
  networth = 0;
  loading = false;
  page = 0;
  limit = 50;
  totalPages = 1;
  txns = [];
  titleY = height / 2 - 650;
  listY = height / 2 - 600;
  items = [];
  paginations = [];

  constructor(scene) {
    super(scene, 'popup-spin-history', { title: 'Spin History' });
    this.scene = scene;

    this.backBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        scene.popupDailySpin?.open();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(this.backBtn);

    this.dateTitle = scene.add
      .text(this.popup.x - this.popup.width / 2 + 200, this.titleY, 'Date/Time', {
        fontSize: '36px',
        fontFamily: fontFamilies.bold,
        color: '#7C2828',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.dateTitle);

    this.rewardTitle = scene.add
      .text(this.popup.x, this.titleY, 'Reward', {
        fontSize: '36px',
        fontFamily: fontFamilies.bold,
        color: '#7C2828',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.rewardTitle);

    this.viewTxnTitle = scene.add
      .text(this.popup.x + this.popup.width / 2 - 200, this.titleY, 'View Txn', {
        fontSize: '36px',
        fontFamily: fontFamilies.bold,
        color: '#7C2828',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.viewTxnTitle);

    this.listContainer = scene.add.image(width / 2, this.listY, 'container-spin-history').setOrigin(0.5, 0);
    this.add(this.listContainer);
    this.contentContainer = scene.add.container().setSize(this.popup.width * 0.8, 0);

    this.loadingIcon = scene.add.image(this.popup.x, this.popup.y, 'icon-loading-small').setVisible(false);
    this.add(this.loadingIcon);
    this.loadingAnimation = scene.tweens.add({
      targets: this.loadingIcon,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimation.pause();

    scene.events.on('s-set-spin-history', ({ totalPages, txns }) => {
      this.totalPages = totalPages;
      this.txns = txns;
      this.setLoading(false);
      this.updateList();
      this.updatePagination();
    });

    scene.events.on('s-set-spin-history-error', () => {
      this.setLoading(false);
    });
  }

  showLoading() {
    this.loadingAnimation.resume();
    this.loadingIcon.setVisible(true);
    this.items.map((item) => item.setAlpha(0.5));
  }

  hideLoading() {
    this.loadingIcon.setVisible(false);
    this.loadingAnimation.pause();
    this.items.map((item) => item.setAlpha(1));
  }

  onOpen() {
    if (this.table) {
      this.table.setMouseWheelScrollerEnable(true);
    }
    this.page = 0;
    this.reloadData();
  }

  cleanup() {
    if (this.table) {
      this.table.setMouseWheelScrollerEnable(false);
      this.thumb?.setVisible(false);
    }
  }

  reloadData() {
    this.setLoading(true);
    this.scene.events.emit('s-get-spin-history', {
      page: this.page,
      limit: this.limit,
    });
  }

  changePage(newPage) {
    if (this.loading) return;
    if (newPage === undefined || newPage === null) return;
    if (newPage < 0 || newPage > this.totalPages - 1) return;
    if (this.page === newPage) return;

    this.page = newPage;
    this.updatePagination();
    this.reloadData();
  }

  setLoading(status) {
    this.loading = status;
    if (status) {
      this.showLoading();
    } else {
      this.hideLoading();
    }
  }

  updateList() {
    if (!this.txns.length) return;

    this.items.map((item) => {
      this.contentContainer.remove(item);
      item.destroy();
    });

    this.items = [];
    for (let i = 0; i < this.txns.length; i++) {
      const { createdAt, reward } = this.txns[i];
      const isFirstPage = this.page === 0;
      const y = isFirstPage ? i * rowHeight + 43 : i * rowHeight;
      const lineX = y + rowHeight * 0.5;
      if (isFirstPage && i === 0) {
        const containerNewItem = this.scene.add.image(this.popup.width / 2 - 90, y + 35, 'container-spin-new-item');
        this.items.push(containerNewItem);
      }

      if (i % 2 === 1) {
        const bg = this.scene.add.image(this.popup.width / 2 - 90, y, 'row-container-119').setOrigin(0.5, 0);
        this.items.push(bg);
      }
      const dateText = this.scene.add
        .text(this.popup.width * 0.12, lineX, createdAt, smallBlackBoldCenter)
        .setOrigin(0.5, 0.5);
      let rewardTextContent = '';
      if (reward) {
        switch (reward.type) {
          case 'house':
            rewardTextContent = `${reward.value} Safehouse${reward.value > 1 ? 's' : ''}`;
            break;
          case 'goon':
            rewardTextContent = `${reward.value} Goon${reward.value > 1 ? 's' : ''}`;
            break;
          case 'pistol':
            rewardTextContent = `${reward.value} Pistol${reward.value > 1 ? 's' : ''}`;
            break;
          case 'shield':
            rewardTextContent = `${reward.value} Shield${reward.value > 1 ? 's' : ''}`;
            break;
          case 'GREED':
            rewardTextContent = `${formatter.format(reward.value)} $GOLD`;
            break;
        }
      }
      const rewardText = this.scene.add
        .text(this.popup.width * 0.45, lineX, rewardTextContent, smallBlackBoldCenter)
        .setOrigin(0.5, 0.5);
      this.items.push(dateText, rewardText);
      if (rewardTextContent) {
        const viewTxnBtn = new Button(
          this.scene,
          this.popup.width * 0.77,
          lineX,
          'icon-search-contained',
          'icon-search-contained',
          () => {
            window.open(getLinkTxn(reward.rewardTxnHash));
          },
          { sound: 'open' }
        );

        this.items.push(viewTxnBtn);
      }
    }
    this.contentContainer.add(this.items);

    const contentContainerHeight = this.txns.length * rowHeight;
    this.contentContainer.setSize(0, contentContainerHeight);
    if (this.table) {
      this.remove(this.table);
      this.table.destroy(true);
      this.table = null;
    }

    if (this.thumb) {
      this.remove(this.thumb);
      this.thumb.destroy(true);
    }

    const tableHeight = this.listContainer.height;
    const visibleRatio = tableHeight / contentContainerHeight;
    this.thumb = this.scene.rexUI.add
      .roundRectangle({
        height: visibleRatio < 1 ? tableHeight * visibleRatio : 0,
        radius: 13,
        color: 0xe3d6c7,
      })
      .setVisible(false);

    this.table = new ScrollablePanel(this.scene, {
      x: width / 2,
      y: this.listY + tableHeight / 2,
      width: this.listContainer.width,
      height: tableHeight,
      scrollMode: 'y',
      background: this.scene.rexUI.add.roundRectangle({ radius: 10 }),
      panel: { child: this.contentContainer, mask: { padding: 1 } },
      slider: { thumb: this.thumb },
      mouseWheelScroller: { focus: true, speed: 0.3 },
      space: { left: 20, right: 20, top: 20, bottom: 20, panel: 20, header: 10, footer: 10 },
    }).layout();
    if (this.txns.length <= 8) {
      this.table.setMouseWheelScrollerEnable(false);
    } else {
      this.table.setMouseWheelScrollerEnable(true);
    }
    this.add(this.table);
    if (!this.visible) {
      this.table.setMouseWheelScrollerEnable(false);
    }

    this.table.on('scroll', (e) => {
      // console.log('scroll', e.t); // e.t === scrolled percentage
      if (this.thumb.visible) return;
      this.thumb.setVisible(true);
    });
  }

  updatePagination() {
    const pageBtns = [{ text: '1', page: 0 }];

    if (this.totalPages <= 5) {
      let count = 1;
      while (count < this.totalPages) {
        pageBtns.push({ text: `${count + 1}`, page: count });
        count++;
      }
    } else {
      if ([0, 1, this.totalPages - 2, this.totalPages - 1].includes(this.page)) {
        pageBtns.push(
          ...[
            { text: '2', page: 1 },
            { text: '...' },
            { text: `${this.totalPages - 1}`, page: this.totalPages - 2 },
            { text: `${this.totalPages}`, page: this.totalPages - 1 },
          ]
        );
      } else {
        pageBtns.push(
          ...[
            { text: '...' },
            { text: `${this.page + 1}`, page: this.page },
            { text: '...' },
            { text: `${this.totalPages}`, page: this.totalPages - 1 },
          ]
        );
      }
    }

    this.paginations.map((item) => {
      this.remove(item);
      item.destroy();
    });

    const canBack = this.page > 0;
    const canNext = this.page < this.totalPages - 1;

    const allPageBtns = [
      {
        text: '<',
        page: this.page - 1,
        color: canBack ? '#C4CDD5' : '#f2f2f2',
        img: canBack ? 'pagination' : 'pagination-disabled',
      },
      ...pageBtns.map((item) => ({
        ...item,
        color: this.page === item.page ? '#7C2828' : '#000000',
        img: this.page === item.page ? 'pagination-active' : 'pagination',
      })),
      {
        text: '>',
        page: this.page + 1,
        color: canNext ? '#C4CDD5' : '#f2f2f2',
        img: canNext ? 'pagination' : 'pagination-disabled',
      },
    ];

    const paginationY = this.listY + this.listContainer.height + 80;
    const paginationWidth = allPageBtns.length * paginationBtnSize + (allPageBtns.length - 1) * paginationBtnGap;
    this.paginations = allPageBtns.map((item, index) => {
      const x =
        width / 2 - paginationWidth / 2 + index * (paginationBtnSize + paginationBtnGap) + paginationBtnSize / 2;
      const btn = new TextButton(
        this.scene,
        x,
        paginationY,
        item.img,
        item.img,
        () => this.changePage(item.page),
        item.text,
        {
          fontSize: '31px',
          color: item.color,
        }
      );
      this.add(btn);
      return btn;
    });
  }
}

export default PopupSpinHistory;
