import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import { fontFamilies } from '../../../../utils/styles';
import { capitalize } from '../../../../utils/strings';
import { customFormat } from '../../../../utils/numbers';
import configs from '../../configs/configs';
import Button from '../button/Button';

const { width } = configs;

const columnStyle = {
  fontSize: '36px',
  fontFamily: fontFamilies.bold,
  color: '#7C2828',
  align: 'center',
};

const itemStyle = {
  fontSize: '36px',
  fontFamily: fontFamilies.bold,
  color: '#29000B',
  align: 'center',
};

const rowHeight = 105;
const buttonWidth = 506;
const btnGap = 50;

class PopupAirdrop extends Popup {
  listY = this.popup.y - 260;
  items = [];
  airdropData = [];
  total = 0;
  claimable = 0;

  constructor(scene) {
    super(scene, 'popup-medium', {
      title: 'Airdrop',
      noBackgroundClick: true,
    });
    this.scene = scene;

    this.backBtn = new TextButton(
      scene,
      this.popup.x - buttonWidth / 2 - btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      'Back',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    this.claimBtn = new TextButton(
      scene,
      this.popup.x + buttonWidth / 2 + btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        this.close();
        scene.popupThugAirdrop?.show({ numberOfThugs: this.claimable });
      },
      'Claim',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.claimBtn.setDisabledState(true);
    this.add(this.claimBtn);

    const columnY = this.listY - 40;

    this.text = scene.add
      .text(
        this.popup.x,
        this.popup.y - this.popup.height / 2 + 190,
        'Link wallets to see if you qualify for a Thug\nairdrop. Available for partners.',
        {
          fontSize: '48px',
          fontFamily: fontFamilies.bold,
          color: '#29000B',
          align: 'center',
        }
      )
      .setOrigin(0.5, 0.5);
    this.add(this.text);

    this.linkWalletBtn = new Button(
      scene,
      this.popup.x,
      this.popup.y - 425,
      'button-link-wallet',
      'button-link-wallet-pressed',
      () => {
        // this.close();
        scene.popupLinkingMetamask?.open();
        scene.events.emit('s-link-wagmi-wallet');
      },
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-link-wallet-disabled' }
    );
    // this.linkWalletBtn.setDisabledState(true);
    this.add(this.linkWalletBtn);

    this.claimableText = scene.add
      .text(this.popup.x, this.popup.y + this.popup.height / 2 - 180, 'Claimable: -/- Thugs', {
        fontSize: '60px',
        fontFamily: fontFamilies.extraBold,
        color: '#29000B',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.claimableText);

    this.walletText = scene.add
      .text(this.popup.x - this.popup.width / 2 + 80, columnY, 'Wallet', columnStyle)
      .setOrigin(0, 0.5);
    this.numberOfThugsText = scene.add.text(this.popup.x - 120, columnY, '# Thugs', columnStyle).setOrigin(0.5, 0.5);
    this.communitiesText = scene.add.text(this.popup.x + 150, columnY, 'Communities', columnStyle).setOrigin(0.5, 0.5);
    this.claimedText = scene.add
      .text(this.popup.x + this.popup.width / 2 - 100, columnY, 'Claimed', columnStyle)
      .setOrigin(1, 0.5);

    this.add(this.walletText);
    this.add(this.numberOfThugsText);
    this.add(this.communitiesText);
    this.add(this.claimedText);

    this.listContainer = scene.add.image(this.popup.x, this.listY, 'container-airdrop').setOrigin(0.5, 0);
    this.add(this.listContainer);
    this.contentContainer = scene.add.container().setSize(this.popup.width * 0.8, 0);

    this.loadingIcon = scene.add.image(this.popup.x, this.popup.y + 140, 'icon-loading-small').setVisible(false);
    this.add(this.loadingIcon);
    this.loadingAnimation = scene.tweens.add({
      targets: this.loadingIcon,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimation.pause();

    scene.events.on('s-set-airdrop-data', ({ data: airdropData, numberOfClaimedAirdropThugs, max, status }) => {
      this.hideLoading();
      this.loading = false;
      this.airdropData = airdropData;
      const claimed = airdropData.filter((item) => item.claimed).reduce((total, item) => total + item.numberOfThugs, 0);

      this.claimable = airdropData
        .filter((item) => !item.claimed)
        .reduce((total, item) => total + item.numberOfThugs, 0);

      this.claimable = Math.min(this.claimable, Math.max((max || 0) - (claimed || 0), 0));
      this.total = airdropData.reduce((total, item) => total + item.numberOfThugs, 0);
      this.claimableText.text = `Claimable: ${this.claimable}/${Math.min(this.claimable, this.total)} (max ${
        max || 50
      })`;

      const canClaim = !!this.claimable && status === 'open';
      this.claimBtn.setDisabledState(!canClaim);
      // this.linkWalletBtn.setDisabledState(status !== 'open');
      this.updateList();
    });
  }

  showLoading() {
    this.loadingAnimation.resume();
    this.loadingIcon.setVisible(true);
    this.items.map((item) => item.setAlpha(0.5));
  }

  hideLoading() {
    this.loadingIcon.setVisible(false);
    this.loadingAnimation.pause();
    this.items.map((item) => item.setAlpha(1));
  }

  reloadData() {
    if (!this.visible || this.loading) return;
    this.updateList();
  }

  updateList() {
    if (!this.airdropData) return;
    const airdropData = this.airdropData;

    this.items.map((item) => {
      this.contentContainer.remove(item);
      item.destroy();
    });

    this.items = [];

    for (let i = 0; i < airdropData.length; i++) {
      const y = i * rowHeight;
      const { address, numberOfThugs, communities, claimed } = airdropData[i];

      if (i % 2 === 1) {
        const bg = this.scene.add
          .image(this.popup.width / 2 - 90, y + rowHeight / 2, 'row-container-105')
          .setOrigin(0.5, 0.5);
        this.items.push(bg);
      }

      const walletAddress = this.scene.add
        .text(this.popup.width * 0.02, y + rowHeight / 2, `${address.slice(0, 5)}...${address.slice(-5)}`, itemStyle)
        .setOrigin(0, 0.5);

      const amount = this.scene.add
        .text(this.popup.width * 0.33, y + rowHeight / 2, `${numberOfThugs}`, itemStyle)
        .setOrigin(0.5, 0.5);

      const community = this.scene.add
        .text(
          this.popup.width * 0.56,
          y + rowHeight / 2,
          !!communities.length ? `${communities.slice(0, 2).join(',\n')}${communities.length > 2 ? '...' : ''}` : '---',
          itemStyle
        )
        .setOrigin(0.5, 0.5);

      const status = this.scene.add
        .text(this.popup.width * 0.8, y + rowHeight / 2, numberOfThugs ? (claimed ? 'Yes' : 'No') : '--', itemStyle)
        .setOrigin(1, 0.5);

      this.items.push(walletAddress, amount, community, status);
    }
    this.contentContainer.add(this.items);

    const contentContainerHeight = airdropData.length * rowHeight;
    this.contentContainer.setSize(0, contentContainerHeight);
    if (this.table) {
      this.table.setScrollerEnable(false);
      this.table.setMouseWheelScrollerEnable(false);
      this.remove(this.table);
      this.table.destroy(true);
      this.table = null;
    }

    if (this.thumb) {
      this.remove(this.thumb);
      this.thumb.destroy(true);
    }

    const tableHeight = this.listContainer.height;
    const visibleRatio = tableHeight / contentContainerHeight;
    this.thumb = this.scene.rexUI.add
      .roundRectangle({
        height: visibleRatio < 1 ? tableHeight * visibleRatio : 0,
        radius: 13,
        color: 0xe3d6c7,
      })
      .setVisible(false);

    this.table = new ScrollablePanel(this.scene, {
      x: width / 2,
      y: this.listY + tableHeight / 2,
      width: this.listContainer.width,
      height: tableHeight,
      scrollMode: 'y',
      background: this.scene.rexUI.add.roundRectangle({ radius: 10 }),
      panel: { child: this.contentContainer, mask: { padding: 1 } },
      slider: { thumb: this.thumb },
      mouseWheelScroller: { focus: true, speed: 0.3 },
      space: { left: 20, right: 20, top: 20, bottom: 20, panel: 20, header: 10, footer: 10 },
    }).layout();
    if (airdropData.length <= 7) {
      this.table.setScrollerEnable(false);
      this.table.setMouseWheelScrollerEnable(false);
    } else {
      this.table.setScrollerEnable(true);
      this.table.setMouseWheelScrollerEnable(true);
    }
    this.add(this.table);
    // if (!this.visible) {
    //   this.table.setScrollerEnable(false);
    //   this.table.setMouseWheelScrollerEnable(false);
    // }

    this.table.on('scroll', (e) => {
      // console.log('scroll', e.t); // e.t === scrolled percentage
      if (this.thumb.visible) return;
      this.thumb.setVisible(true);
    });
  }

  onOpen() {
    this.showLoading();
    this.scene.events.emit('s-get-airdrop-data');
  }
}

export default PopupAirdrop;
