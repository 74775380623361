import { useLocation } from 'react-router-dom';
import { Box, Button, alpha } from '@mui/material';

import userAgent from '../utils/userAgent';

const BannedModel = () => {
  const { pathname } = useLocation();

  return (
    <Box
      zIndex={9999}
      position="fixed"
      top={0}
      left={0}
      width={{ xs: '100vw' }}
      height="100vh"
      bgcolor={alpha('#000', 0.5)}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
      sx={{
        backgroundImage: {
          xs: 'url(/images/bg-login-vertical.webp)',
          sm: 'url(/images/bg-login-5x4.webp)',
          md: 'url(/images/bg-login.webp)',
        },
        backgroundSize: 'cover',
        '& img': {
          xs: { maxWidth: '80vw', maxHeight: '80vh' },
          sm: { maxWidth: '40vw', maxHeight: '80vh' },
          md: { maxWidth: '30vw', maxHeight: '80vh' },
        },
      }}>
      <img width={{ xs: '50wv' }} src="/images/banned.png" alt="banned" />
      <Box
        style={{
          backgroundImage: 'url(/images/banned.png)',
        }}>
        <Button
          style={{
            backgroundImage: 'url(/images/button-blue-normal.png)',
            backgroundSize: 'contain',
            color: 'white',
            marginTop: '-50px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          onClick={() => {
            window.location.href = 'https://discord.gg/unchartedgg';
          }}
          title="Contact Support">
          Contact Support
        </Button>
      </Box>
      {/* </img> */}
    </Box>
  );
};

export default BannedModel;
