import Button from './Button';
import { formatter } from '../../../../utils/numbers';

class ActiveClaimButton extends Button {
  reward = 0;
  isSimulator = false;

  constructor(scene, x, y, { parent }) {
    super(scene, x, y, 'button-blue', 'button-blue-pressed', () => {
      if (this.loading) return;
      if (this.rewardText.text === '0') return;

      const onClaim = ({ checked } = {}) => {
        localStorage.setItem('claim-confirmation-disabled', checked ? 'true' : 'false');
        parent.startCoinAnimation();
        this.loading = true;
        this.coinImage?.setVisible(false);
        this.text.text = 'Claiming...';
        this.text.x = 0;
        scene.events.emit('s-claim');
      };

      const disabled = localStorage.getItem('claim-confirmation-disabled');

      if (this.isSimulator || disabled === 'true') {
        onClaim();
      } else {
        scene.popupClaimConfirm?.show({ reward: this.reward, onClaim });
      }
    });

    this.isSimulator = scene.name === 'TutorialScene';

    this.text = scene.add
      .text(-30, -40, 'Claim', {
        fontSize: '82px',
        color: '#fff',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(0.5, 0.5);
    this.text.setStroke('#0004A0', 3);

    this.rewardText = scene.add
      .text(0, 35, '', {
        fontSize: '60px',
        color: '#fff',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(0.5, 0.5);
    this.rewardText.setStroke('#0004A0', 3);

    this.coinImage = scene.add.image(0 + this.text.width / 2 + 20, -35, 'icon-xgang').setOrigin(0.5, 0.5);
    this.coinImage.setScale(0.75);

    this.add(this.text);
    this.add(this.rewardText);
    this.add(this.coinImage);

    scene.events.on('s-claim-completed', () => {
      this.loading = false;
      this.coinImage?.setVisible(true);
      this.text.text = 'Claim';
      this.text.x = -30;
      scene.events.emit('s-set-claimable-status', { claimable: false, active: true });
    });

    scene.events.on('s-set-claimable-reward', ({ reward }) => {
      this.reward = reward;
      this.rewardText.text = formatter.format(reward);
    });
  }
}

export default ActiveClaimButton;
