import Phaser from 'phaser';

import configs from '../configs/configs';
import Background from '../components/common/Background';
import Header from '../components/action-buttons/Header';
import InfoButtons from '../components/action-buttons/InfoButtons';
import GangsterHouse from '../components/common/GangsterHouse';
import Footer from '../components/action-buttons/Footer';
import PopupBuy from '../components/popup/PopupBuy';
import PopupWar from '../components/popup/PopupWar';
import PopupBuyGangster from '../components/popup/PopupBuyGangster';
import Animation from '../components/common/Animation';
import PopupLeaderboard from '../components/popup/PopupLeaderboard';
import PopupDeposit from '../components/popup/PopupDeposit';
import PopupPrizePool from '../components/popup/PopupPrizePool';
import Tutorial from '../components/tutorials/Tutorial';
import PopupDepositETH from '../components/popup/PopupDepositETH';

const { width } = configs;

class TutorialScene extends Phaser.Scene {
  name = 'TutorialScene';
  globalEvents = [];

  constructor() {
    super('TutorialScene');
  }

  // listeners for global events here
  addGlobalEventListener(name, fn) {
    this.globalEvents.push(name);
    this.game.events.on(name, fn, this);
  }

  createLocalToGlobalEventFlow(name) {
    this.events.on(`s-${name}`, (data) => this.game.events.emit(`g-${name}`, data), this);
  }

  createGlobalToLocalEventFlow(name) {
    this.addGlobalEventListener(`g-${name}`, (data) => this.events.emit(`s-${name}`, data));
  }

  initListeners() {
    // create event flow
    const localToGlobalEvents = [
      'get-leaderboard-list',
      'get-market-data',
      'get-season',
      'buy-gangster',
      'claim',
      'get-eth-balance',
      'get-deposit-code',
      'get-rank',
    ];
    const globalToLocalEvents = [
      'set-leaderboard-list',
      'set-market-data',
      'set-season',
      'set-machines',
      'buy-gangster-completed',
      'set-animation-assets',
      'set-networth-house',
      'claim-completed',
      'set-x-token-balance',
      'set-eth-balance',
      'set-deposit-code',
      'set-rank',
    ];

    for (const event of localToGlobalEvents) {
      this.createLocalToGlobalEventFlow(event);
    }

    for (const event of globalToLocalEvents) {
      this.createGlobalToLocalEventFlow(event);
    }

    // interact with game components

    // listen to global event
  }

  emitStartingEvents() {
    const startingEvents = ['g-get-networth-house', 'g-get-rank'];

    for (const event of startingEvents) {
      this.game.events.emit(event, { isSimulator: true });
    }
  }

  create() {
    this.initListeners();
    this.emitStartingEvents();
  }

  // remove global event listeners when scene destroy
  removeListeners() {
    for (const event of this.globalEvents) {
      this.game.events.removeListener(event);
    }
  }

  shutdown() {
    this.removeListeners();
  }

  endTutorial() {
    this.bgMusic.stop();
    this.game.events.emit('g-tutorial-end');
    this.infoButtons?.rankButton?.cleanup?.();
    this.animationLayer?.cleanup?.();
    this.scene.start('MainScene', { isFromTutorial: true });
    this.scene.remove('TutorialScene');
  }

  preload() {
    this.bgMusic = this.sound.add('bg', { loop: true, volume: 0.15 });

    this.background = new Background(this, 'bg'); // done
    this.add.existing(this.background);

    this.animationLayer = new Animation(this); // done
    this.add.existing(this.animationLayer);

    const gangsterHouse = new GangsterHouse(this, 2200); // done
    this.add.existing(gangsterHouse);

    this.popupDeposit = new PopupDeposit(this, null, {
      noBackground: true,
      originY: -220,
      onOpen: () => {
        this.tutorial.step12.hideArrow();
      },
      onClose: () => this.endTutorial(),
    }); // done
    this.add.existing(this.popupDeposit);

    this.popupDepositETH = new PopupDepositETH(this, {
      noBackground: true,
      originY: -220,
      onOpen: () => {
        this.tutorial.step12.arrow.setVisible(false);
        this.tutorial.background
          .setInteractive()
          .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => this.endTutorial());
        this.tutorial.step12.character.updateCallback(() => this.endTutorial());
      },
      onClose: () => this.endTutorial(),
    });
    this.add.existing(this.popupDepositETH);

    this.popupBuy = new PopupBuy(this, width - 335, 1310);
    this.add.existing(this.popupBuy);

    this.header = new Header(this, 250); // done
    this.add.existing(this.header);

    this.popupWar = new PopupWar(this, 35, 1600);
    this.add.existing(this.popupWar);

    if (this.game.sound.mute) {
      this.bgMusic.stop();
    } else {
      this.bgMusic.play();
    }

    const pluginLoader = this.load.scenePlugin({
      key: 'rexuiplugin',
      url: '/libs/rexui.min.js',
      sceneKey: 'rexUI',
    });
    pluginLoader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.popupBuyGangster = new PopupBuyGangster(this, {
        noCloseBtn: true,
        noBackground: true,
        originY: -100,
        onCompleted: () => {
          this.tutorial.step4.setVisible(false);
          this.tutorial.setVisible(false);
          setTimeout(() => {
            this.tutorial.setVisible(true);
            this.tutorial.step5.setVisible(true);
          }, 300);
        },
      }); // done
      this.add.existing(this.popupBuyGangster);

      this.popupLeaderboard = new PopupLeaderboard(this, { noBackground: true });
      this.add.existing(this.popupLeaderboard);

      this.popupPrizePool = new PopupPrizePool(this); // done
      this.add.existing(this.popupPrizePool);

      this.footer = new Footer(this, 2600); // done
      this.footer.setDepth(1);
      this.add.existing(this.footer);
    });

    this.infoButtons = new InfoButtons(this, 550); // done
    this.add.existing(this.infoButtons);

    this.tutorialOverlay = this.add.container(0, 0).setDepth(6);
    this.add.existing(this.tutorialOverlay);
    this.tutorial = new Tutorial(this, this.tutorialOverlay);
    this.add.existing(this.tutorial);
    this.tutorial.setDepth(2);
  }
}

export default TutorialScene;
