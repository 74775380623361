import Popup from './Popup';
import PopupProcessing from './PopupProcessing';
import PopupConfirm from './PopupConfirm';
import TextInput from '../inputs/TextInput';
import TextButton from '../button/TextButton';
import ImageButton from '../button/ImageButton';
import configs from '../../configs/configs';
import { numberCharacterRegex, numberInputRegex } from '../../../../utils/strings';
import { formatter } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';

const { width, height } = configs;
const mediumBrownBold = { fontSize: '50px', color: colors.brown, fontFamily: fontFamilies.bold };

class PopupSwap extends Popup {
  loading = false;
  error = false;
  ethBalance = 0;
  tokenBalance = 0;
  xTokenBalance = 0;
  gas = 0;
  tokenSwap = 'eth';
  timeout = null;
  interval = null;
  loading = false;
  mode = 'web3';

  constructor(scene, parentModal) {
    super(scene, 'popup-mini', { title: 'Swap' });

    const leftMargin = this.popup.x - this.popup.width / 2;
    const textX = leftMargin + this.popup.width * 0.1;
    const startingY = this.popup.y - this.popup.height / 2;
    const youPayY = startingY + 150;
    const token1AmountInputY = youPayY + 170;
    const youReceiveY = token1AmountInputY + 170;
    const token2AmountInputY = youReceiveY + 170;

    this.popupProcessing = new PopupProcessing(scene, {
      completedEvent: 's-swap-completed',
      completedIcon: 'swap-eth-token',
      description: `Swapping may take a few minutes.`,
    });
    scene.add.existing(this.popupProcessing);
    this.popupConfirm = new PopupConfirm(scene, this, {
      title: 'Swap',
      action: 'swap',
      icon1: 'icon-eth-small',
      icon2: 'icon-coin-small',
      onConfirm: () => {
        const data = {
          tokenSwap: this.tokenSwap,
          amount: Number(this.token1AmountInput.value),
        };
        scene.events.emit('s-swap', data);
      },
    });
    scene.add.existing(this.popupConfirm);

    const youPay = scene.add
      .text(textX, youPayY, 'You pay:', {
        fontSize: fontSizes.large,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, 0.5);
    this.token1AmountInput = new TextInput(scene, width / 2, token1AmountInputY, {
      icon: 'icon-eth',
      placeholder: '0.00',
      unit: 'ETH',
      valueRegex: numberInputRegex,
      characterRegex: numberCharacterRegex,
      maxDisplayedCharacters: 13,
      onChange: (value) => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        if (!value || !Number(value)) {
          scene.events.emit(
            this.tokenSwap === 'eth' ? 's-convert-eth-input-to-token-result' : 's-convert-token-input-to-eth-result',
            { amount: '0.00', fee: 0 }
          );

          return;
        }

        this.setLoading(true);

        this.timeout = setTimeout(
          () =>
            scene.events.emit(
              this.tokenSwap === 'eth' ? 's-convert-eth-input-to-token' : 's-convert-token-input-to-eth',
              {
                amount: Number(value),
              }
            ),
          500
        );
      },
    });

    this.balanceText = scene.add
      .text(width - textX, youPayY, '0.00', {
        fontSize: fontSizes.medium,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.available = scene.add.text(width - textX - 200, youPayY, 'Available:', mediumBrownBold).setOrigin(1, 0.5);
    this.add(youPay);
    this.add(this.token1AmountInput);
    this.add(this.balanceText);
    this.add(this.available);

    const youReceive = scene.add
      .text(textX, youReceiveY, 'You receive:', {
        fontSize: fontSizes.large,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, 0.5);
    this.token2AmountInput = new TextInput(scene, width / 2, token2AmountInputY, {
      icon: 'gang-coin-small',
      placeholder: '0.00',
      unit: '$GOLD',
      valueRegex: numberInputRegex,
      characterRegex: numberCharacterRegex,
      maxDisplayedCharacters: 18,
      onChange: (value) => {},
    });
    this.token2AmountInput.setDisabled(true);
    this.add(youReceive);
    this.add(this.token2AmountInput);

    this.switchBtn = new ImageButton(
      scene,
      this.popup.x + this.popup.width / 2 - 130,
      youReceiveY,
      'button-square-small',
      'button-square-small-pressed',
      () => this.switch(),
      'swap-arrow',
      { sound: 'button-1' }
    );
    this.add(this.switchBtn);

    const buttonBack = new TextButton(
      scene,
      width / 2 - this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        parentModal.open();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.buttonApprove = new TextButton(
      scene,
      width / 2 + this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        if (this.loading) return;

        // TODO: show validation to user
        const isValid = this.validate();
        if (!isValid) return;

        this.popupConfirm.updateTextLeft(
          `${formatter.format(Number(this.token1AmountInput.value).toPrecision(3))}         `
        );
        this.popupConfirm.updateTextRight(formatter.format(Number(this.token2AmountInput.value).toPrecision(3)));
        this.close();
        this.popupConfirm.open();
      },
      'Approve',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.buttonApprove.setDisabledState(true);
    this.add(buttonBack);
    this.add(this.buttonApprove);

    this.maxBtn = new TextButton(
      scene,
      width - textX - 100,
      token1AmountInputY,
      'button-blue-short',
      'button-blue-short-pressed',
      () => {
        const fee = this.tokenSwap === 'eth' ? Math.min(this.ethBalance, this.gas) : Math.min(this.tokenBalance, 1);
        const balance = this.tokenSwap === 'eth' ? this.ethBalance : Math.floor(this.tokenBalance);
        const displayedBalance = balance - fee;
        this.token1AmountInput.updateValue(displayedBalance.toString(), true, true);
        this.setLoading(true);
        this.timeout = setTimeout(
          () =>
            scene.events.emit(
              this.tokenSwap === 'eth' ? 's-convert-eth-input-to-token' : 's-convert-token-input-to-eth',
              { amount: balance }
            ),
          500
        );
      },
      'Max',
      { fontSize: '82px', sound: 'button-1' }
    );
    this.add(this.maxBtn);

    scene.events.on('s-set-gas-swap', ({ gas }) => {
      if (isNaN(gas)) return;
      this.gas = gas;
    });
    scene.events.on('s-set-balances', ({ ETHBalance, tokenBalance }) => {
      this.updateBalance({ ETHBalance, tokenBalance });
    });

    scene.events.on('s-swap-completed', () => this.setLoading(false));
    scene.events.on('s-swap-started', ({ txnHash }) => {
      this.setLoading(false);
      this.popupProcessing.initLoading(`Swapping may take a few minutes.`);
      this.close();
    });
    scene.events.on('s-swap-error', () => {
      this.setLoading(false);
      this.setError(true);
    });
    scene.events.on('s-convert-eth-input-to-token-result', ({ amount }) => {
      this.token2AmountInput.updateValue(`${amount}`, true, true);
      this.setLoading(false);
      this.setError(false);
    });
    scene.events.on('s-convert-token-input-to-eth-result', ({ amount }) => {
      this.token2AmountInput.updateValue(`${amount}`, true, true);
      this.setLoading(false);
      this.setError(false);
    });
  }

  switch() {
    if (this.loading) return;

    this.token1AmountInput.updateValue('0.00', true, true);
    this.token2AmountInput.updateValue('0.00', true, true);

    if (this.tokenSwap === 'eth') {
      this.tokenSwap = 'token';
      this.token1AmountInput.changeIcon('gang-coin-small');
      this.token2AmountInput.changeIcon('icon-eth');
      this.popupConfirm.updateIconLeft('icon-coin-small');
      this.popupConfirm.updateIconRight('icon-eth-small');
      this.balanceText.text = `${formatter.format(this.tokenBalance)}`;
      this.popupProcessing.updateCompletedIcon('swap-token-eth');
      this.token1AmountInput.changeUnit('$GOLD');
      this.token2AmountInput.changeUnit('ETH');
    } else {
      this.tokenSwap = 'eth';
      this.token1AmountInput.changeIcon('icon-eth');
      this.token2AmountInput.changeIcon('gang-coin-small');
      this.popupConfirm.updateIconLeft('icon-eth-small');
      this.popupConfirm.updateIconRight('icon-coin-small');
      this.balanceText.text = `${formatter.format(this.ethBalance)}`;
      this.popupProcessing.updateCompletedIcon('swap-eth-token');
      this.token1AmountInput.changeUnit('ETH');
      this.token2AmountInput.changeUnit('$GOLD');
    }
    this.available.x = this.balanceText.x - this.balanceText.width - 20;
  }

  onOpen() {
    // reset form
    this.token1AmountInput.updateValue('', true, true);
    this.token2AmountInput.updateValue('', true, true);
    this.buttonApprove?.setDisabledState(true);
    this.scene.events.emit('s-get-gas-swap');
  }

  validate() {
    let isValid = true;
    if (!this.token1AmountInput.value || !this.token2AmountInput.value) return false;

    const inputAmount = Number(this.token1AmountInput.value);
    const userAmount = this.tokenSwap === 'eth' ? this.ethBalance - this.gas : this.tokenBalance;

    if (!inputAmount || inputAmount > userAmount) isValid = false;

    return isValid;
  }

  setLoading(state) {
    this.loading = state;
    const status = state;
    const isValid = this.validate();
    this.buttonApprove.setDisabledState(status || !isValid);
  }

  setError(state) {
    this.error = state;
    const status = state;
    const isValid = this.validate();
    this.buttonApprove.setDisabledState(status || !isValid);
  }

  cleanup() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  updateBalance({ ETHBalance, tokenBalance }) {
    this.ethBalance = ETHBalance;
    this.tokenBalance = tokenBalance;
    if (this.mode !== 'web3') return;
    this.balanceText.text = `${formatter.format(this.tokenSwap === 'eth' ? ETHBalance : tokenBalance)}`;
    this.available.x = this.balanceText.x - this.balanceText.width - 20;
  }
}

export default PopupSwap;
