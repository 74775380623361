import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { fontFamilies } from '../../../../utils/styles';

const { width, height } = configs;

const buttonWidth = 506;
const btnGap = 50;

class PopupGangwarRequirement extends Popup {
  constructor(scene) {
    super(scene, 'popup-gangwar-requirement', { title: 'Need more!' });

    this.cancelBtn = new TextButton(
      scene,
      width / 2 - buttonWidth / 2 - btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      'Cancel',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.cancelBtn);

    this.buyNowBtn = new TextButton(
      scene,
      width / 2 + buttonWidth / 2 + btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        this.close();
        scene.popupBuyGangster?.open();
      },
      'Buy Now',
      { sound: 'button-1', fontSize: '82px' }
    );
    this.add(this.buyNowBtn);

    this.numberOfMachinesText = scene.add
      .text(this.popup.x - this.popup.width / 2 + 245, this.popup.y + this.popup.height / 2 - 235, '-', {
        fontSize: '56px',
        fontFamily: fontFamilies.extraBold,
        color: '#F13F49',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfMachinesText);

    this.requirementText = scene.add
      .text(this.popup.x - 150, this.popup.y - 100, 'You must have', {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#7D2E00',
        align: 'center',
      })
      .setOrigin(0, 0.5);
    this.add(this.requirementText);

    this.requirementText2 = scene.add
      .text(
        this.requirementText.x,
        this.requirementText.y + 80,
        'gangsters to start raiding\nfor rewards in Gang Wars.',
        {
          fontSize: '48px',
          fontFamily: fontFamilies.bold,
          color: '#7D2E00',
          align: 'center',
        }
      )
      .setOrigin(0, 0.5);
    this.add(this.requirementText2);

    this.numberOfMachineRequirementText = scene.add
      .text(this.requirementText.x + this.requirementText.width + 10, this.requirementText.y, '- or more', {
        fontSize: '48px',
        fontFamily: fontFamilies.extraBold,
        color: '#7D2E00',
      })
      .setOrigin(0, 0.5);
    this.add(this.numberOfMachineRequirementText);
  }

  show({ numberOfMachines, gangwarMinNumberOfMachines }) {
    this.numberOfMachinesText.text = `${numberOfMachines}`;
    this.numberOfMachineRequirementText.text = `${gangwarMinNumberOfMachines} or more`;
    this.open();
  }
}

export default PopupGangwarRequirement;
