import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies } from '../../../../utils/styles';

const { width, height } = configs;

class PopupWarExplain extends Popup {
  tokenRewardPerEarner = 0;
  earningStealPercent = 0;
  machinePercentLost = 0;

  constructor(scene, { onClickBackButton, ...configs } = {}) {
    super(scene, 'popup-war-explain', { title: 'Gang War', titleIcon: 'icon-info', noCloseBtn: true, ...configs });
    this.onClickClose = onClickBackButton;

    this.backBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        if (onClickBackButton) {
          onClickBackButton();
          return;
        }
        scene.popupWarMachines?.open();
      },
      'Back',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    const startTextX = width / 2 - 180;
    const tokenRewardY = height / 2 - 310;
    const earningStealPercentY = tokenRewardY + 415;
    const machinePercentLostY = earningStealPercentY + 300;

    this.tokenRewardText = scene.add
      .text(
        startTextX,
        tokenRewardY,
        `Earn additional ${this.tokenRewardPerEarner.toLocaleString()}\nxGOLD per Gangster.`,
        {
          fontSize: '48px',
          color: '#7B2A29',
          fontFamily: fontFamilies.bold,
        }
      )
      .setOrigin(0, 1);
    this.add(this.tokenRewardText);

    this.earningStealPercentText = scene.add
      .text(
        startTextX,
        earningStealPercentY,
        `If successful, steal ${this.earningStealPercent * 100}% of\ntheir earned xGOLD and\ngain raid pts.`,
        {
          fontSize: '48px',
          color: '#7B2A29',
          fontFamily: fontFamilies.bold,
        }
      )
      .setOrigin(0, 1);
    this.add(this.earningStealPercentText);

    this.machinePercentLostText = scene.add
      .text(
        startTextX,
        machinePercentLostY,
        `If successful, kill ${this.machinePercentLost * 100}% of\nGangster attackers.`,
        {
          fontSize: '48px',
          color: '#7B2A29',
          fontFamily: fontFamilies.bold,
        }
      )
      .setOrigin(0, 1);
    this.add(this.machinePercentLostText);

    scene.events.on('s-set-war-config', ({ tokenRewardPerEarner, earningStealPercent, machinePercentLost }) => {
      this.tokenRewardPerEarner = tokenRewardPerEarner;
      this.earningStealPercent = earningStealPercent;
      this.machinePercentLost = machinePercentLost;
      this.updateValues();
    });
  }

  onOpen() {
    this.scene.events.emit('s-get-war-config');
  }

  updateValues() {
    this.tokenRewardText.text = `Earn additional ${this.tokenRewardPerEarner.toLocaleString()}\nxGOLD per Gangster.`;
    this.earningStealPercentText.text = `If successful, steal ${
      this.earningStealPercent * 100
    }% of\ntheir earned xGOLD and\ngain raid pts.`;
    this.machinePercentLostText.text = `If successful, kill ${this.machinePercentLost * 100}% of\nGangster attackers.`;
  }
}

export default PopupWarExplain;
